import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import '../../css/main-style.css';
import { useDispatch, useSelector } from 'react-redux';
import PDFIcon from '../icons/PDF-icon';
import DocumentListItem from './DocumentListItem';
import PrimaryIconText from './PrimaryIconText';
import SecondaryIconText from '../lists/SecondaryDateText';
import {
  documentTypeToUrl,
  downloadFileById,
  fileIsPdf,
  formatDate,
  getPreviousDocumentRevisions,
  getImageByFileId,
  getTitle,
  openInNewTab,
  parseDate,
} from '../../scripts/utils';
import { getDocumentState, getSubmittalSectionFile } from '../../features/document/selectors';
import { Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import { getDocumentsType } from '../../features/documents/selectors';
import {
  DocumentPriorityType,
  DocumentTemplateType,
  FileCategoryType,
  IFile,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import DownloadIcon from '../icons/Download-icon';
import { getBidState } from '../../features/bid/selectors';
import { getProjectState } from '../../features/project/selectors';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { getFileById } from '../../models/api/files';
import { fetchDocument, reloadDocument, updateDocument } from '../../features/document/actions';
import IconButton from '@material-ui/core/IconButton';
import { Delete, NoteAdd, Remove, Replay } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import {
  deleteFile,
  replaceFileByDocumentIdAndFileId,
  uploadGeneralDocumentFile,
} from '../../models/api/filesystem';
import { addSnackbar } from '../../features/snackbar/actions';
import {
  getDocPermission,
  hasAdminPermissions,
  hasCompletedConstructionSetup,
} from '../../scripts/store-utils';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import CircularLoader from '../loader/CircularLoader';
import { getUserState } from '../../features/user/selectors';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import { CancelButton } from '../custom-components/CustomButtons';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import SelectParserDialog from '../dialogs/SelectParserDialog';

interface SupportingDocumentsCardProps {
  title?: string;
  isBidTabulation?: boolean;
  submittedDate?: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      zIndex: -1,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    pos: {
      marginBottom: 12,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
    },
    responsibleMargin: {
      marginRight: 10,
    },
    buttonMargin: {
      marginRight: 8,
    },
    submittalTimeStamp: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    noPadding: {
      padding: 0,
      margin: 0,
    },
    iconText: {
      color: '#000', // black
      paddingLeft: 0,
      fontSize: 14,
    },
    icon: {
      minWidth: 0,
      paddingRight: theme.spacing(2),
      marginLeft: 0,
    },
    button: {
      margin: 1,
    },
    iconButton: {},
    dividerMarginTop: {
      marginTop: theme.spacing(2),
    },
    dividerMarginBot: {
      marginBottom: theme.spacing(2),
      marginTop: 0,
    },
  }),
);

const baseFileCategories = [
  FileCategoryType.DocumentBase,
  FileCategoryType.SpecificationSection,
  FileCategoryType.DrawingsSection,
];

function fileCategoryTypeDescendingSort(a: FileCategoryType, b: FileCategoryType) {
  if (baseFileCategories.includes(a)) return 1;
  if (baseFileCategories.includes(b)) return -1;
  return 0;
}

function fileDescendingSort(a: IFile, b: IFile) {
  const dateA = parseDate(a.createdOn!).valueOf();
  const dateB = parseDate(b.createdOn!).valueOf();

  if (dateA === dateB) {
    return fileCategoryTypeDescendingSort(a.category, b.category);
  }

  return dateB - dateA;
}

const SupportingDocumentsCard: React.FC<SupportingDocumentsCardProps> = (props) => {
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const user = useSelector(getUserState);
  const document = useSelector(getDocumentState);
  const bid = useSelector(getBidState);
  const submittalSectionFile = useSelector(getSubmittalSectionFile);
  const docType = useSelector(getDocumentsType);
  const project = useSelector(getProjectState);
  const dispatch = useDispatch();
  const history = useHistory();
  const security = useSelector(getCurrentSecurityGroup);

  const { title = undefined, isBidTabulation, submittedDate } = props;
  const submitDate = submittedDate ? parseDate(submittedDate).isBefore(dayjs()) : false;
  const isAfterSubmittedDate = isBidTabulation ? submitDate : true;

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [previousFilesOpen, setPreviousFilesOpen] = useState(true);
  // useEffect(() => {
  //   if (docType !== DocumentTemplateType.Submittals) setPreviousFilesOpen(true);
  // }, [docType]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [imageUrl, setImageUrl] = useState('');

  const [reuploadFileDialogOpen, setReuploadFileDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [reuploadFile, setReuploadFile] = useState<File>();

  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [inputFile, setInputFile] = useState<File>();
  const [inputComment, setInputComment] = useState('');

  const [fileToConform, setFileToConform] = useState<IFile>();
  const [conformDialogOpen, setConformDialogOpen] = useState(false);

  const permission = getDocPermission();

  const canDelete = permission === 4;
  const canUpload = permission && permission >= 2;

  const documentIsRevision = !!(document?.revisionNumber && document.revisionNumber > 0);

  const pastBiddingDate = () => {
    const dueDate = project?.bidSetup?.dueDate;
    if (dueDate) {
      return dayjs().isAfter(parseDate(dueDate));
    }
    return false;
  };

  const handleDeleteFile = async (event: React.MouseEvent, id?: string) => {
    try {
      await deleteFile(document!.id, id || primaryFile!.id);
      dispatch(fetchDocument(document!.id));
      dispatch(
        addSnackbar({ id: Date.now(), message: 'File successfully deleted', severity: 'success' }),
      );
    } catch (e: any) {}
  };

  const canConform = () => {
    return (
      !!docType &&
      [
        DocumentTemplateType.AsiDocuments,
        DocumentTemplateType.Addenda,
        DocumentTemplateType.BidDrawings,
        DocumentTemplateType.ProjectManual,
        DocumentTemplateType.ConstructionChangeDirectives,
      ].includes(docType) &&
      hasCompletedConstructionSetup() &&
      (!!security?.publishingCenterPermission || hasAdminPermissions())
    );
  };

  const getFiles = (): IFile[] => {
    let files: IFile[] | undefined;
    if (document) {
      files = document.files?.slice().sort(fileDescendingSort) || [];
      if (document.drawingsSectionFile) files.push(document.drawingsSectionFile);
      if (
        document.submittalSectionFile &&
        !files.some((f) => f.category === FileCategoryType.SpecificationSection)
      )
        files.push(document.submittalSectionFile);
    }
    if (bid) {
      files = bid.files?.slice().sort((a, b) => descendingComparator(a, b, 'createdOn')) || [];
    }
    files = files?.filter(
      (f) =>
        f.category !== FileCategoryType.DocumentBluebeamStudioLog && !f.isHiddenInSupportingItems,
    );
    return _.uniqBy(files, ({ id }) => id) || [];
  };

  const files = getFiles();

  const getPrimaryFile = () => {
    if (bid) return null;

    if (
      docType === DocumentTemplateType.PayApplications ||
      docType === DocumentTemplateType.DesignPackages
    ) {
      const fileIndex = files.findIndex((f) =>
        docType === DocumentTemplateType.PayApplications
          ? f.category === FileCategoryType.DocumentFinalExecuted
          : f.category === FileCategoryType.DocumentFinalDeliverable,
      );
      if (fileIndex !== -1) return files.splice(fileIndex, 1)[0];
    }

    if (docType === DocumentTemplateType.Addenda) return files.length > 0 ? files[0] : null;

    return files.splice(0, 1)[0];
  };

  const primaryFile = getPrimaryFile();

  const allFiles =
    docType === DocumentTemplateType.Addenda
      ? files.map((file) => (
          <div key={file.id}>
            <DocumentListItem
              file={file}
              show={isAfterSubmittedDate}
              canDelete={canDelete}
              handleDelete={handleDeleteFile}
              canConform={canConform}
              handleConform={() => {
                setFileToConform(file);
                setConformDialogOpen(true);
              }}
            />
          </div>
        ))
      : null;
  const previousFiles =
    files.length > 0
      ? files.map((d) => {
          return (
            <div key={d.id}>
              <DocumentListItem
                file={d}
                show={isAfterSubmittedDate}
                canDelete={canDelete}
                handleDelete={handleDeleteFile}
                canConform={canConform}
                handleConform={() => {
                  setFileToConform(d);
                  setConformDialogOpen(true);
                }}
              />
            </div>
          );
        })
      : [];

  const downloadDocumentFile = async () => {
    if (primaryFile)
      try {
        setIsDownloading(true);
        await downloadFileById(primaryFile.id, primaryFile.name, undefined);
      } finally {
        setIsDownloading(false);
      }
  };

  const shouldAllowReupload = () => {
    if (!document?.workflowStatus) return false;
    return (
      [WorkflowStatusType.SubmittedForReview, WorkflowStatusType.UnderReview].includes(
        document.workflowStatus,
      ) &&
      document.files
        ?.filter((f) => f.category === FileCategoryType.DocumentGeneralContractorApproved)
        .every((f) => !f.isVerified)
    );
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleFileReupload = async () => {
    if (!document || !primaryFile || !reuploadFile) return;
    await replaceFileByDocumentIdAndFileId(
      document.id,
      primaryFile.id,
      reuploadFile,
      handleUploadProgress,
    );
    dispatch(reloadDocument());
  };

  const handleCancelUpload = async () => {
    if (!document || !primaryFile) return;
    await deleteFile(document.id, primaryFile.id);
    dispatch(reloadDocument());
  };

  const getConformDisabled = () => {
    return !!primaryFile?.isConformedInDrawings && !!primaryFile?.isConformedInSpecifications;
  };

  const getButtons = () => {
    if (primaryFile?.isVerified === false) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
          <Button variant="outlined" color="primary" disabled className={classes.button}>
            Upload in progress...
          </Button>
          {(primaryFile?.creatorUserId === user.id || user.isSiteAdmin) && (
            <>
              {shouldAllowReupload() ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<Replay />}
                  onClick={() => setReuploadFileDialogOpen(true)}
                  className={classes.button}
                  style={{ width: 170 }}
                >
                  Re-Upload
                </Button>
              ) : (
                <CancelButton
                  variant="outlined"
                  onClick={handleCancelUpload}
                  className={classes.button}
                  style={{
                    width: 170,
                    borderColor: '#ED3F26',
                    color: '#ED3F26',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Cancel Upload
                </CancelButton>
              )}
            </>
          )}
        </div>
      );
    }
    return (
      <div style={{ paddingLeft: 8, paddingBottom: 8 }}>
        {primaryFile &&
          fileIsPdf(primaryFile) &&
          (isPhoneScreen ? (
            <IconButton
              aria-label="view"
              color="primary"
              onClick={() => openInNewTab(primaryFile.id)}
              className={classes.iconButton}
              style={{ paddingRight: 12 }}
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openInNewTab(primaryFile.id)}
              className={classes.button}
              disableElevation
            >
              <VisibilityIcon /> &nbsp; View
            </Button>
          ))}
        {isPhoneScreen ? (
          <IconButton
            aria-label="download"
            color="primary"
            onClick={downloadDocumentFile}
            className={classes.iconButton}
            style={{ paddingLeft: fileIsPdf(primaryFile!) ? 0 : 18, paddingRight: 12 }}
          >
            <GetAppRoundedIcon />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadDocumentFile}
            className={classes.button}
            disableElevation
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            {!isDownloading ? (
              <DownloadIcon fill="#2C69D6" />
            ) : (
              <CircularLoader size={20} style={{ marginRight: 4 }} />
            )}{' '}
            Download
          </Button>
        )}
        {canConform() ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<NoteAdd />}
            disabled={getConformDisabled()}
            onClick={() => {
              setFileToConform(primaryFile!);
              setConformDialogOpen(true);
            }}
          >
            Conform
          </Button>
        ) : null}
        {permission === 4 && !document?.isHidden ? (
          isPhoneScreen ? (
            <IconButton
              color="inherit"
              aria-label="delete"
              className={classes.iconButton}
              onClick={(event) => handleDeleteFile(event)}
              style={{
                color: '#ED3F26',
                paddingLeft: 0,
                paddingRight: 12,
              }}
            >
              <Delete />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              onClick={(event) => handleDeleteFile(event)}
              className={classes.button}
              disableElevation
              style={{
                height: '32px',
                color: '#ED3F26',
                width: '130px',
                border: '2px solid #ED3F26',
                borderRadius: '4px',
              }}
            >
              <Delete style={{ marginRight: '6px' }} /> Delete
            </Button>
          )
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (submittalSectionFile?.thumbnailFileId && !imageUrl)
      getImageByFileId(submittalSectionFile!.thumbnailFileId!).then((url) => setImageUrl(url));
  }, [submittalSectionFile]);

  useEffect(() => {
    if (document && document.files) {
      const interval = setInterval(async () => {
        const unverfiedFiles = document.files!.filter((f) => !f.isVerified);
        if (unverfiedFiles.length === 0) clearInterval(interval);
        for (let i = 0; i < unverfiedFiles.length; i += 1) {
          const file = unverfiedFiles[i];
          const { isVerified } = await getFileById(file.id);
          if (isVerified) {
            const newDocument = { ...document };
            const fileToChange = newDocument.files?.find((f) => f.id === file.id);
            if (fileToChange) {
              fileToChange.isVerified = true;
              dispatch(updateDocument(newDocument)).then(() => {
                if (!document.files?.some((f) => !f.isVerified)) clearInterval(interval);
              });
            }
          }
        }
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [document?.files]);

  const getDocumentDescription = () => {
    if (!docType) return null;
    let description: string | undefined | null = null;
    switch (docType) {
      case DocumentTemplateType.Task:
      case DocumentTemplateType.RequestsForInformation:
      case DocumentTemplateType.RequestsForChange:
      case DocumentTemplateType.PotentialChangeOrders:
        description = document?.description;
        break;
      case DocumentTemplateType.Submittals:
        if (document?.documentTemplate?.name === DocumentTemplateType.SubmittalPackages)
          description = document?.title;
        else description = document?.description;
        break;
      case DocumentTemplateType.CloseoutSubmittals:
        if (document?.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages)
          description = document?.title;
        break;
    }
    return description ?? null;
  };

  const getOriginalDocumentLink = () => {
    if (!document) return;
    const originalDocument = getPreviousDocumentRevisions(document)[0];
    return (
      <Typography style={{ fontWeight: 500, fontSize: 16 }}>
        Original Document:{' '}
        <b
          onClick={() =>
            history.push(
              `/main/projects/${originalDocument.projectId}/documents/${
                documentTypeToUrl[docType!]
              }/${originalDocument.id}`,
            )
          }
          style={{ cursor: 'pointer', color: '#0947B9' }}
        >
          {getTitle(docType!, originalDocument)}
        </b>
      </Typography>
    );
  };

  const handleUploadTaskFile = async () => {
    if (!document || !inputFile) return;
    try {
      dispatch(blockNavigation());
      await uploadGeneralDocumentFile(
        document.id,
        {
          fileType: FileCategoryType.DocumentAttachments,
          fullFileName: inputFile.name,
          useMultiPartUpload: false,
          comment: { text: inputComment },
          enableCommentNotification: true,
        },
        inputFile,
        handleUploadProgress,
      );
      dispatch(reloadDocument());
    } finally {
      dispatch(allowNavigation());
      setInputFile(undefined);
      setUploadProgress(0);
      setUploadFileDialogOpen(false);
    }
  };

  return (
    // override shadow here because parent element is supposed to have it
    <Card className={classes.root} style={{ padding: 0, height: collapseOpen ? '100%' : 'auto' }}>
      <CardContent className={classes.content} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <h2 style={{ flexGrow: 1 }} className="h2">
            {title
              ? `${title} Supporting Items  `
              : `${document?.referenceNumber}${
                  document?.revisionNumber ? `-R${document?.revisionNumber}` : ''
                } Supporting Items`}
          </h2>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          <IconButton style={{ padding: 0 }}>
            {collapseOpen ? (
              <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
            ) : (
              <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
            )}
          </IconButton>
        </div>
        {documentIsRevision && getOriginalDocumentLink()}
      </CardContent>
      <Collapse in={collapseOpen}>
        {docType === DocumentTemplateType.Task &&
          document?.priority === DocumentPriorityType.ForInformationOnly &&
          canUpload && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUploadFileDialogOpen(true)}
              style={{ marginLeft: 16, marginBottom: 16 }}
            >
              Add A File
            </Button>
          )}
        {document && getDocumentDescription() && (
          <>
            <Divider className={classes.dividerMarginBot} />
            <Grid container direction="column">
              <Typography className={classes.pos} color="textSecondary" style={{ marginLeft: 16 }}>
                Description
              </Typography>
              <Typography
                paragraph
                style={{
                  fontSize: 19,
                  marginLeft: 16,
                  whiteSpace: 'pre-line',
                }}
              >
                {getDocumentDescription()}
              </Typography>
            </Grid>
            <Divider className={classes.dividerMarginBot} />
          </>
        )}
        {document && docType !== DocumentTemplateType.Addenda && (
          <Grid container alignItems="flex-start">
            {imageUrl && submittalSectionFile?.thumbnailFileId && files.length < 2 ? (
              <Grid item style={{ paddingLeft: 24 }}>
                <img width={140} src={imageUrl} alt="" style={{ border: '1px solid #7A797A' }} />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
              style={{ marginLeft: '8px' }}
            >
              <Grid item xs style={{ width: '100%' }}>
                <List disablePadding>
                  {primaryFile ? (
                    <>
                      <Typography className={classes.pos} color="textSecondary">
                        Current File
                      </Typography>
                      {primaryFile.category === FileCategoryType.DocumentFinalExecuted && (
                        <Typography style={{ fontWeight: 700 }}>Final Executed</Typography>
                      )}
                      {primaryFile.category === FileCategoryType.DocumentFinalDeliverable && (
                        <Typography style={{ fontWeight: 700 }}>Final Deliverable</Typography>
                      )}
                      {primaryFile.category === FileCategoryType.DocumentBluebeamStudioLog && (
                        <Typography style={{ fontWeight: 700 }}>
                          Bluebeam Studio Activity Log
                        </Typography>
                      )}
                      <ListItem
                        className={classes.noPadding}
                        style={{ display: 'flex', width: '100%' }}
                      >
                        <ListItemIcon className={classes.icon}>
                          <PDFIcon />
                        </ListItemIcon>
                        <ListItemText
                          style={{ flexGrow: 1 }}
                          primary={
                            <PrimaryIconText
                              text={primaryFile.name}
                              user={primaryFile.creatorUser?.name}
                              company={primaryFile?.creatorUser?.company?.name}
                            />
                          }
                          secondary={
                            <SecondaryIconText
                              date={formatDate(primaryFile.createdOn ? primaryFile.createdOn : '')}
                            />
                          }
                        />
                      </ListItem>
                    </>
                  ) : (
                    <div>No primary file</div>
                  )}
                </List>
              </Grid>
              {primaryFile && isAfterSubmittedDate && getButtons()}
            </Grid>
          </Grid>
        )}
        {document && docType !== DocumentTemplateType.Addenda && (
          <Divider className={classes.dividerMarginTop} />
        )}
        {document && docType !== DocumentTemplateType.Addenda && (
          <CardContent className={classes.content}>
            {previousFiles.length > 0 ? (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                    style={{ marginBottom: 0 }}
                  >
                    Previous Files
                  </Typography>
                  <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                  <IconButton style={{ padding: 0 }}>
                    {previousFilesOpen ? (
                      <Remove htmlColor="#0947B9" onClick={() => setPreviousFilesOpen(false)} />
                    ) : (
                      <Add htmlColor="#0947B9" onClick={() => setPreviousFilesOpen(true)} />
                    )}
                  </IconButton>
                </div>
                <Collapse in={previousFilesOpen}>
                  <List>{previousFiles}</List>
                </Collapse>
              </>
            ) : (
              <div>No previous files</div>
            )}
          </CardContent>
        )}
        {document && docType === DocumentTemplateType.Addenda && (
          <CardContent className={classes.content} style={{ paddingTop: 0 }}>
            <List style={{ paddingTop: 0 }}>{allFiles}</List>
          </CardContent>
        )}
        {bid && (
          <CardContent className={classes.content} style={{ paddingTop: 0 }}>
            {files.length > 0 ? (
              <>
                <Typography className={classes.pos} color="textSecondary">
                  Attached Files
                </Typography>
                <List>
                  {files.map((d) => {
                    return (
                      <DocumentListItem
                        file={d}
                        key={d.id}
                        show={isAfterSubmittedDate}
                        disabled={!pastBiddingDate()}
                        canConform={canConform}
                        handleConform={() => {
                          setFileToConform(d);
                          setConformDialogOpen(true);
                        }}
                      />
                    );
                  })}
                </List>
              </>
            ) : (
              <div>No attached files</div>
            )}
          </CardContent>
        )}
      </Collapse>
      <FileUploadDialog
        open={reuploadFileDialogOpen}
        handleClose={() => setReuploadFileDialogOpen(false)}
        title="Reupload File"
        addFile={(f) => setReuploadFile(f as File)}
        removeFile={() => setReuploadFile(undefined)}
        file={reuploadFile}
        canSubmit={!!reuploadFile}
        buttonType="submit"
        handleSubmit={handleFileReupload}
        uploadProgress={uploadProgress}
        requireFileName={primaryFile?.name}
        disableComments
        disableDesignUpload
      />
      <FileUploadDialog
        open={uploadFileDialogOpen}
        handleClose={() => setUploadFileDialogOpen(false)}
        title="Upload File"
        addFile={(f) => setInputFile(f as File)}
        removeFile={() => setInputFile(undefined)}
        file={inputFile}
        canSubmit={!!inputFile}
        inputComment={inputComment}
        handleChangeComment={(e) => setInputComment(e.target.value)}
        buttonType="submit"
        handleSubmit={handleUploadTaskFile}
        uploadProgress={uploadProgress}
        disableDesignUpload
      />
      {canConform() ? (
        <SelectParserDialog
          open={conformDialogOpen}
          handleClose={() => {
            setConformDialogOpen(false);
            setFileToConform(undefined);
          }}
          file={fileToConform}
        />
      ) : null}
    </Card>
  );
};

export default SupportingDocumentsCard;
