import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Add, EditOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import PageTitle from '../../page-title/PageTitle';
import NavAppbar from '../../nav-top/NavAppbar';
import {
  DocumentTemplateType,
  IInsertionSubscriberSecurityGroup,
  InboxPermissionLevel,
  ISecurityGroup,
  ISecurityGroupUserSummary,
  PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId,
  SecurityPermissionLevel,
} from '../../../api-client/autogenerated';
import {
  deleteSecurityGroupById,
  editSecurityGroupBySubscriberId,
  getAllSecurityGroupsBySubscriberId,
  getProjectUserSummaryBySecurityGroupId,
  insertSecurityGroupBySubscriberId,
} from '../../../models/api/security-groups';
import { getUserState } from '../../../features/user/selectors';
import { getUserById } from '../../../models/api/users';
import {
  CancelButton,
  MediumButton,
  SmallButton,
  SubmitButton,
} from '../../custom-components/CustomButtons';
import PermissionControl from './PermissionControl';

import { getTemplates } from '../../../models/api/templates';
import FormMode from './FormMode';
import DeleteSecurityGroupDialog from './DeleteSecurityGroupDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularLoader from '../../loader/CircularLoader';
import { addSnackbar } from '../../../features/snackbar/actions';
import { ascendingComparator } from '../../document-index/DocumentIndexUtils';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: 8,
    paddingBottom: 32,
  },
  submit: {
    margin: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    //padding: theme.spacing(3),
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  alignLeft: {
    textAlign: 'left',
  },
  paddingLeft: {
    paddingLeft: 15,
    paddingTop: 10,
  },
  checkbox: {
    paddingLeft: 25,
    marginLeft: 20,
  },
  loadingCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  loadingNotCenter: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  loadingMargin: {
    paddingBottom: 16,
  },
  title: {
    padding: 0,
  },
  textField: {
    maxWidth: 300,
    paddingTop: 4,
    paddingBottom: 8,
  },
  textFieldSmall: {
    maxWidth: 142,
  },

  //TODO: get the screenwidth and make the maxWidth based on those calculations
  //padding: isForAdminDashboard ? '24px 0px' : '24px'
  rootGrid: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100vw',
      paddingTop: '',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: 0,
      width: '90vw',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    padding: 0,
  },
  fileInstructions: {
    maxWidth: 500,
  },
  endDiv: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8,
  },
  widthConstraint: {
    maxWidth: 1300,
  },
  inline: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingBottom: 8,
  },
  pdf: {
    width: 32,
    height: 32,
    marginRight: 16,
  },
  deleteButton: {
    backgroundColor: '#ED3F26',
    '&:hover': {
      backgroundColor: '#FF5D45',
    },
    '&:focus': {
      backgroundColor: '#FF5D45',
    },
  },
}));

interface Props {
  isForAdminDashboard?: boolean;
}

export default function SecurityGroupsPage(props: Props) {
  const classes = useStyles();
  const authUser = useSelector(getUserState);
  const dispatch = useDispatch();
  const { subscriberId } = useParams<{ subscriberId: string }>();

  const { isForAdminDashboard } = props;

  const [securityGroups, setSecurityGroups] = useState<ISecurityGroup[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<ISecurityGroup | undefined>(undefined);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [securityGroupSummary, setSecurityGroupSummary] = useState<ISecurityGroupUserSummary>();

  const editAnchor = useRef<any>(null);
  const addAnchor = useRef<any>(null);

  const [templates, setTemplates] = useState<undefined | Dictionary<string>>(undefined);

  const [mode, setMode] = useState<FormMode>(0);

  const [securityGroupInput, setSecurityGroupInput] = useState<string>('');
  const [tempName, setTempName] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const setInitialState = (type: DocumentTemplateType) => {
    let val = null;
    if (selectedGroup && templates) {
      try {
        val = selectedGroup!.securityGroupDocumentTemplateList!.find(
          (x) => x.documentTemplateId === templates[type],
        )!.securityPermissionLevel!;
      } catch (err: any) {
        val = null;
      }
    }
    return val;
  };

  const [userGroups, setUserGroups] = useState<boolean>(false);
  const [manageProjectUsers, setManageProjectUsers] = useState<SecurityPermissionLevel | null>(
    null,
  );

  const [correspondenceLog, setCorrespondenceLog] = useState<InboxPermissionLevel | null>(null);

  const [design, setDesign] = useState<SecurityPermissionLevel | null>(null);

  const [bidManagement, setBidManagement] = useState<boolean>(false);
  const [bidTabulation, setBidTabulation] = useState<boolean>(false);
  const [planholderList, setPlanholderList] = useState<boolean>(false);
  const [bidDrawings, setBidDrawings] = useState<SecurityPermissionLevel | null>(null);
  const [projectManual, setProjectManual] = useState<SecurityPermissionLevel | null>(null);
  const [addenda, setAddenda] = useState<SecurityPermissionLevel | null>(null);
  const [informationalItems, setInformationalItems] = useState<SecurityPermissionLevel | null>(
    null,
  );
  const [substitutionRequests, setSubstitutionRequests] = useState<SecurityPermissionLevel | null>(
    null,
  );
  const [bidderRFIs, setBidderRFIs] = useState<SecurityPermissionLevel | null>(null);

  const [regulatoryApprovals, setRegulatoryApprovals] = useState<SecurityPermissionLevel | null>(
    null,
  );

  const [constructionManagement, setConstructionManagement] = useState<boolean>(false);
  const [publishingCenter, setPublishingCenter] = useState<boolean>(false);
  const [drawings, setDrawings] = useState<SecurityPermissionLevel | null>(null);
  const [specifications, setSpecifications] = useState<SecurityPermissionLevel | null>(null);
  const [ASIDocuments, setASIDocuments] = useState<SecurityPermissionLevel | null>(null);
  const [submittals, setSubmittals] = useState<SecurityPermissionLevel | null>(null);
  const [
    requestsForInformation,
    setRequestsForInformation,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [changeOrders, setChangeOrders] = useState<SecurityPermissionLevel | null>(null);
  const [
    constChangeDirectives,
    setConstChangeDirectives,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [
    potentialChangeOrders,
    setPotentialChangeOrders,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [requestsForChange, setRequestsForChange] = useState<SecurityPermissionLevel | null>(null);
  const [
    workChangeProposalReq,
    setWorkChangeProposalReq,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [payApplications, setPayApplications] = useState<SecurityPermissionLevel | null>(null);
  const [contractorDailyLogs, setContractorDailyLogs] = useState<SecurityPermissionLevel | null>(
    null,
  );
  const [fieldReports, setFieldReports] = useState<SecurityPermissionLevel | null>(null);
  const [meetingMinutes, setMeetingMinutes] = useState<SecurityPermissionLevel | null>(null);
  const [
    miscellaneousDocuments,
    setMiscellaneousDocuments,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [schedules, setSchedules] = useState<SecurityPermissionLevel | null>(null);
  const [testing, setTesting] = useState<SecurityPermissionLevel | null>(null);
  const [additionalReview, setAdditionalReview] = useState<SecurityPermissionLevel | null>(null);

  const [asBuilt, setAsBuilt] = useState<SecurityPermissionLevel | null>(null);
  const [closeoutSubmittals, setCloseoutSubmittals] = useState<SecurityPermissionLevel | null>(
    null,
  );
  const [OMData, setOMData] = useState<SecurityPermissionLevel | null>(null);
  const [punchList, setPunchList] = useState<SecurityPermissionLevel | null>(null);
  const [warrantyItems, setWarrantyItems] = useState<SecurityPermissionLevel | null>(null);
  const [
    substantialCompletionCert,
    setSubstantialCompletionCert,
  ] = useState<SecurityPermissionLevel | null>(null);
  const [clearLP, setClearLP] = useState<SecurityPermissionLevel | null>(null);
  const [tasks, setTasks] = useState<SecurityPermissionLevel | null>(null);
  const [designPackages, setDesignPackages] = useState<SecurityPermissionLevel | null>(null);

  const getSelectedGroupName = () => {
    let str;
    try {
      str = securityGroups.find((x) => x.id === selectedGroupId)!.name;
    } catch {
      str = '';
    }
    return str;
  };

  useEffect(() => {
    getTemplates().then((res) => {
      setTemplates(res);
    });
  }, []);

  useEffect(() => {
    if (mode !== FormMode.CREATE) {
      setSelectedGroup(securityGroups.find((x) => x.id === selectedGroupId));
      setSecurityGroupInput(getSelectedGroupName);
      if (selectedGroupId) {
        setDeleteLoading(true);
        getProjectUserSummaryBySecurityGroupId(selectedGroupId).then((summary) => {
          setSecurityGroupSummary(summary);
          setDeleteLoading(false);
        });
      }
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (!isForAdminDashboard) {
      if (!authUser.adminOfSubscriberId) return;

      getAllSecurityGroupsBySubscriberId(authUser.adminOfSubscriberId).then(setSecurityGroups);
    } else if (subscriberId) {
      getAllSecurityGroupsBySubscriberId(subscriberId).then(setSecurityGroups);
    }
  }, [authUser, isForAdminDashboard, subscriberId]);

  useEffect(() => {
    if (mode !== FormMode.CREATE && selectedGroup) {
      setUserGroups(selectedGroup.canModifyUserGroups || false);
      setManageProjectUsers(selectedGroup.manageProjectUsersPermission ?? null);

      setCorrespondenceLog(selectedGroup.correspondenceInboxPermission ?? null);

      setDesign(selectedGroup.designTabPermission ?? null);
      setTasks(setInitialState(DocumentTemplateType.Task));
      setDesignPackages(setInitialState(DocumentTemplateType.DesignPackages));

      setBidManagement(!!selectedGroup.bidManagementPermission);
      setBidDrawings(setInitialState(DocumentTemplateType.BidDrawings));
      setProjectManual(setInitialState(DocumentTemplateType.ProjectManual));
      setAddenda(setInitialState(DocumentTemplateType.Addenda));
      setInformationalItems(setInitialState(DocumentTemplateType.InformationalItems));
      setSubstitutionRequests(setInitialState(DocumentTemplateType.SubstitutionRequests));
      setBidderRFIs(setInitialState(DocumentTemplateType.BidderRfIs));
      setPlanholderList(!!selectedGroup.planholderListPermission);
      setBidTabulation(!!selectedGroup.bidTabulationPermission);
      setRegulatoryApprovals(setInitialState(DocumentTemplateType.RegulatoryApprovals));

      setConstructionManagement(!!selectedGroup.constructionManagementPermission);
      setPublishingCenter(!!selectedGroup.publishingCenterPermission);
      setDrawings(setInitialState(DocumentTemplateType.Drawings));
      setSpecifications(setInitialState(DocumentTemplateType.Specifications));
      setASIDocuments(setInitialState(DocumentTemplateType.AsiDocuments));
      setSubmittals(setInitialState(DocumentTemplateType.Submittals));
      setRequestsForInformation(setInitialState(DocumentTemplateType.RequestsForInformation));
      setChangeOrders(setInitialState(DocumentTemplateType.ChangeOrders));
      setConstChangeDirectives(setInitialState(DocumentTemplateType.ConstructionChangeDirectives));
      setPotentialChangeOrders(setInitialState(DocumentTemplateType.PotentialChangeOrders));
      setRequestsForChange(setInitialState(DocumentTemplateType.RequestsForChange));
      setWorkChangeProposalReq(setInitialState(DocumentTemplateType.WorkChangeProposalRequests));
      setPayApplications(setInitialState(DocumentTemplateType.PayApplications));
      setContractorDailyLogs(setInitialState(DocumentTemplateType.ContractorDailyLogs));
      setFieldReports(setInitialState(DocumentTemplateType.FieldReports));
      setMeetingMinutes(setInitialState(DocumentTemplateType.MeetingMinutes));
      setMiscellaneousDocuments(setInitialState(DocumentTemplateType.MiscellaneousDocuments));
      setSchedules(setInitialState(DocumentTemplateType.Schedules));
      setTesting(setInitialState(DocumentTemplateType.Testing));
      setAdditionalReview(setInitialState(DocumentTemplateType.AdditionalReview));

      setAsBuilt(setInitialState(DocumentTemplateType.AsBuilt));
      setCloseoutSubmittals(setInitialState(DocumentTemplateType.CloseoutSubmittals));
      setOMData(setInitialState(DocumentTemplateType.OperationsAndMaintenanceData));
      setPunchList(setInitialState(DocumentTemplateType.PunchList));
      setWarrantyItems(setInitialState(DocumentTemplateType.WarrantyItems));
      setSubstantialCompletionCert(
        setInitialState(DocumentTemplateType.CertificateOfSubstantialCompletion),
      );
      setClearLP(setInitialState(DocumentTemplateType.ClearLp));
    } else {
      setUserGroups(false);
      setManageProjectUsers(null);

      setCorrespondenceLog(null);

      setDesign(null);
      setTasks(null);
      setDesignPackages(null);

      setBidManagement(false);
      setBidDrawings(null);
      setProjectManual(null);
      setAddenda(null);
      setInformationalItems(null);
      setSubstitutionRequests(null);
      setBidderRFIs(null);
      setPlanholderList(false);
      setBidTabulation(false);
      setRegulatoryApprovals(null);

      setConstructionManagement(false);
      setPublishingCenter(false);
      setDrawings(null);
      setSpecifications(null);
      setASIDocuments(null);
      setSubmittals(null);
      setRequestsForInformation(null);
      setChangeOrders(null);
      setConstChangeDirectives(null);
      setPotentialChangeOrders(null);
      setRequestsForChange(null);
      setWorkChangeProposalReq(null);
      setPayApplications(null);
      setContractorDailyLogs(null);
      setFieldReports(null);
      setMeetingMinutes(null);
      setMiscellaneousDocuments(null);
      setSchedules(null);
      setTesting(null);
      setAdditionalReview(null);

      setAsBuilt(null);
      setCloseoutSubmittals(null);
      setOMData(null);
      setPunchList(null);
      setWarrantyItems(null);
      setSubstantialCompletionCert(null);
      setClearLP(null);
    }
  }, [selectedGroup, mode]);

  const createSecurityGroup = (): IInsertionSubscriberSecurityGroup => {
    const docPerms: PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId[] = [];
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.BidDrawings],
      securityPermissionLevel: bidDrawings,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.ProjectManual],
      securityPermissionLevel: projectManual,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Addenda],
      securityPermissionLevel: addenda,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.InformationalItems],
      securityPermissionLevel: informationalItems,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.SubstitutionRequests],
      securityPermissionLevel: substitutionRequests,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.BidderRfIs],
      securityPermissionLevel: bidderRFIs,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.PlanholderList],
      securityPermissionLevel: planholderList ? SecurityPermissionLevel.NUMBER_4 : null,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.BidTabulation],
      securityPermissionLevel: bidTabulation ? SecurityPermissionLevel.NUMBER_4 : null,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.RegulatoryApprovals],
      securityPermissionLevel: regulatoryApprovals,
    });

    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Drawings],
      securityPermissionLevel: drawings,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Specifications],
      securityPermissionLevel: specifications,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.AsiDocuments],
      securityPermissionLevel: ASIDocuments,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Submittals],
      securityPermissionLevel: submittals,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.SubmittalPackages],
      securityPermissionLevel: submittals,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.RequestsForInformation],
      securityPermissionLevel: requestsForInformation,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.ChangeOrders],
      securityPermissionLevel: changeOrders,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.ConstructionChangeDirectives],
      securityPermissionLevel: constChangeDirectives,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.PotentialChangeOrders],
      securityPermissionLevel: potentialChangeOrders,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.RequestsForChange],
      securityPermissionLevel: requestsForChange,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.WorkChangeProposalRequests],
      securityPermissionLevel: workChangeProposalReq,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.PayApplications],
      securityPermissionLevel: payApplications,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.ContractorDailyLogs],
      securityPermissionLevel: contractorDailyLogs,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.FieldReports],
      securityPermissionLevel: fieldReports,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.MeetingMinutes],
      securityPermissionLevel: meetingMinutes,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.MiscellaneousDocuments],
      securityPermissionLevel: miscellaneousDocuments,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Schedules],
      securityPermissionLevel: schedules,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Testing],
      securityPermissionLevel: testing,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.AdditionalReview],
      securityPermissionLevel: additionalReview,
    });

    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.AsBuilt],
      securityPermissionLevel: asBuilt,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.AsBuiltPackages],
      securityPermissionLevel: asBuilt,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.CloseoutSubmittals],
      securityPermissionLevel: closeoutSubmittals,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.CloseoutSubmittalPackages],
      securityPermissionLevel: closeoutSubmittals,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.OperationsAndMaintenanceData],
      securityPermissionLevel: OMData,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.PunchList],
      securityPermissionLevel: punchList,
    });
    // docPerms.push({
    //   documentTemplateId: templates!['Punch List Setup'],
    //   securityPermissionLevel: punchListSetup,
    // });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.WarrantyItems],
      securityPermissionLevel: warrantyItems,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.CertificateOfSubstantialCompletion],
      securityPermissionLevel: substantialCompletionCert,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.ClearLp],
      securityPermissionLevel: clearLP,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.Task],
      securityPermissionLevel: tasks,
    });
    docPerms.push({
      documentTemplateId: templates![DocumentTemplateType.DesignPackages],
      securityPermissionLevel: designPackages,
    });

    return {
      name: securityGroupInput,
      securityGroupDocumentTemplateList: docPerms,
      canModifyUserGroups: userGroups,
      designTabPermission: design,
      constructionManagementPermission: constructionManagement
        ? SecurityPermissionLevel.NUMBER_4
        : null,
      publishingCenterPermission: publishingCenter ? SecurityPermissionLevel.NUMBER_4 : null,
      planholderListPermission: planholderList ? SecurityPermissionLevel.NUMBER_4 : null,
      bidManagementPermission: bidManagement ? SecurityPermissionLevel.NUMBER_4 : null,
      bidTabulationPermission: bidTabulation ? SecurityPermissionLevel.NUMBER_4 : null,
      manageProjectUsersPermission: manageProjectUsers ? SecurityPermissionLevel.NUMBER_4 : null,
      correspondenceInboxPermission: correspondenceLog,
    };
  };

  const insertSecurityGroup = () => {
    const securityGroup = createSecurityGroup();

    getUserById(authUser.id!).then((subscriber) => {
      insertSecurityGroupBySubscriberId(subscriber.adminOfSubscriberId!, securityGroup).then(
        (res) => {
          setMode(FormMode.NONE);
          const newSecurityGroups = [...securityGroups];
          newSecurityGroups.push(res);
          setSecurityGroups(newSecurityGroups);
          setSelectedGroupId(res.id);
          setSelectedGroup(res);
        },
      );
    });
  };

  const patchSecurityGroup = () => {
    const securityGroup = createSecurityGroup();

    getUserById(authUser.id!).then((subscriber) => {
      editSecurityGroupBySubscriberId(
        subscriber.adminOfSubscriberId!,
        selectedGroupId!,
        securityGroup,
      ).then((res) => {
        setMode(FormMode.NONE);
        const newSecurityGroups = [...securityGroups];
        const index = newSecurityGroups.findIndex((x) => x.id === selectedGroupId);
        newSecurityGroups[index] = res;
        setSecurityGroups(newSecurityGroups);
        setSelectedGroupId(res.id);
        setSelectedGroup(res);
      });
    });
  };

  const handleDelete = async () => {
    if (!securityGroupSummary || !selectedGroupId) return;

    if (securityGroupSummary.users.length === 0) {
      try {
        await deleteSecurityGroupById(selectedGroupId);
        setSelectedGroupId('');
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Security group successfully deleted!',
            severity: 'success',
          }),
        );
        setSecurityGroups((groups) => groups.filter((g) => g.id !== selectedGroupId));
      } catch (e: any) {
        console.error(e);
      }
    } else {
      setDeleteDialogOpen(true);
    }
  };

  const transferUsersAndDelete = async (newSecurityGroupId: string) => {
    if (!securityGroupSummary || !selectedGroupId) return;

    try {
      await deleteSecurityGroupById(selectedGroupId, newSecurityGroupId);
      setDeleteDialogOpen(false);
      setSelectedGroupId('');
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Users transferred and old security group successfully deleted!',
          severity: 'success',
        }),
      );
      setSecurityGroups((groups) => groups.filter((g) => g.id !== selectedGroupId));
    } catch (e: any) {
      console.error(e);
    }
  };

  const getToolbarButtons = () => {
    if (mode === FormMode.NONE)
      return (
        <>
          <SmallButton
            variant="contained"
            color="primary"
            className={classes.deleteButton}
            style={{ marginRight: '8px' }}
            onClick={handleDelete}
            disabled={!selectedGroupId || deleteLoading}
          >
            {deleteLoading ? (
              <CircularLoader
                size={16}
                loaderStyle={{ color: 'white' }}
                style={{ marginLeft: 4, marginRight: 4 }}
              />
            ) : (
              <DeleteIcon style={{ width: 20, height: 20, paddingRight: 2 }} />
            )}
            Delete
          </SmallButton>
          <SmallButton
            variant="contained"
            color="primary"
            ref={editAnchor}
            style={{ marginRight: '4px' }}
            onClick={() => {
              setMode(FormMode.EDIT);
              setTempName(securityGroupInput);
            }}
            disabled={!selectedGroupId}
          >
            <EditOutlined style={{ width: 20, height: 20, paddingRight: 2 }} />
            Edit
          </SmallButton>
          <MediumButton
            variant="contained"
            color="primary"
            ref={addAnchor}
            style={{ marginLeft: '4px', marginRight: '4px' }}
            onClick={() => {
              setMode(FormMode.CREATE);
              setSecurityGroupInput('');
            }}
          >
            <Add style={{ paddingRight: 2 }} />
            CREATE
          </MediumButton>
        </>
      );

    if (mode === FormMode.EDIT)
      return (
        <>
          <CancelButton
            style={{ marginRight: '4px', height: 32 }}
            onClick={() => {
              setMode(FormMode.NONE);
            }}
          >
            CANCEL
          </CancelButton>
          {tempName === securityGroupInput ? (
            <SubmitButton
              variant="contained"
              style={{ minWidth: '100px', marginLeft: '4px', height: 32 }}
              onClick={patchSecurityGroup}
            >
              SAVE CHANGES
            </SubmitButton>
          ) : (
            <SubmitButton
              variant="contained"
              style={{ minWidth: '100px', marginLeft: '4px', height: 32 }}
              onClick={insertSecurityGroup}
            >
              Create Group
            </SubmitButton>
          )}
        </>
      );

    // FormMode.CREATE
    return (
      <>
        <CancelButton
          style={{ marginRight: '4px', height: 32 }}
          onClick={() => {
            setMode(FormMode.NONE);
          }}
        >
          CANCEL
        </CancelButton>
        <SubmitButton
          variant="contained"
          style={{ minWidth: '100px', marginLeft: '4px', height: 32 }}
          onClick={insertSecurityGroup}
        >
          Create Group
        </SubmitButton>
      </>
    );
  };

  return (
    //Had to remove this padding for Mobile: padding: isForAdminDashboard ? '24px 0px' : '24px'
    <main className={classes.root}>
      {!isForAdminDashboard && (
        <>
          <NavAppbar />
          <Grid container justify="center" sm={12}>
            <Grid item xs={12} style={{ paddingLeft: isMobile ? 10 : 0 }}>
              <PageTitle title="Manage Security Groups" />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={3} justify="center" className={classes.rootGrid}>
        <Grid item xs={12} container>
          <Card className={classes.paper}>
            <form className={classes.form}>
              <div className={classes.widthConstraint}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.title} variant="h2">
                      Manage security groups
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Selected Security Group</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        paddingBottom: 16,
                        alignItems: 'center',
                        justifyContent: isMobile ? 'center' : 'initial',
                      }}
                    >
                      <FormControl>
                        {mode === FormMode.NONE ? (
                          <Select
                            variant="outlined"
                            defaultValue=""
                            value={selectedGroupId}
                            displayEmpty
                            style={{ minWidth: 300, marginRight: 24 }}
                            onChange={(event) => setSelectedGroupId(event.target.value as string)}
                          >
                            <MenuItem value="" disabled>
                              Select Security Group
                            </MenuItem>
                            {securityGroups
                              ?.sort((a, b) => ascendingComparator(a, b, 'name'))
                              .map((group) => {
                                return (
                                  <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        ) : (
                          <TextField
                            variant="outlined"
                            id="security-group-name"
                            placeholder="Security Group Name"
                            name="Security Group Name"
                            defaultValue={securityGroupInput}
                            required
                            onBlur={(e) => setSecurityGroupInput(e.target.value)}
                            style={{ minWidth: 300, marginRight: 24 }}
                          />
                        )}
                      </FormControl>

                      {!isMobile && getToolbarButtons()}
                    </div>
                    {isMobile && (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {' '}
                        {getToolbarButtons()}{' '}
                      </div>
                    )}
                    <Divider light style={{ marginTop: 16, marginBottom: 16 }} />

                    <div style={{ paddingBottom: 16, display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Users
                      </Typography>
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="User Groups"
                        disableView
                        disableComment
                        disableCreate
                        disableReview
                        value={userGroups ? SecurityPermissionLevel.NUMBER_4 : null}
                        setValue={(value) => setUserGroups(!!value)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Manage Project Users"
                        disableView
                        disableCreate
                        disableComment
                        disableReview
                        value={manageProjectUsers}
                        setValue={setManageProjectUsers}
                      />
                      <Divider
                        style={{ marginTop: 16, marginBottom: 16, opacity: isMobile ? 0 : 100 }}
                      />
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Email
                      </Typography>
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Correspondence Log"
                        disableReview
                        disableComment
                        value={correspondenceLog}
                        setValue={
                          setCorrespondenceLog as React.Dispatch<
                            React.SetStateAction<SecurityPermissionLevel | null>
                          >
                        }
                        customLabels={{ viewLabel: 'Personal', createLabel: 'Company' }}
                      />
                      <Divider
                        style={{ marginTop: 16, marginBottom: 16, opacity: isMobile ? 0 : 100 }}
                      />
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Design
                      </Typography>
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="File Manager"
                        disableCreate
                        disableComment
                        disableReview
                        value={design}
                        setValue={setDesign}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Task & Share Manager"
                        disableReview
                        value={tasks}
                        setValue={setTasks}
                        customLabels={{ createLabel: 'Create/Review' }}
                        customValues={{ createValue: SecurityPermissionLevel.NUMBER_3 }}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Design Package Review"
                        disableReview
                        value={designPackages}
                        setValue={setDesignPackages}
                        customLabels={{ createLabel: 'Create/Review' }}
                        customValues={{ createValue: SecurityPermissionLevel.NUMBER_3 }}
                      />
                      <Divider
                        style={{ marginTop: 16, marginBottom: 16, opacity: isMobile ? 0 : 100 }}
                      />
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Bidding
                      </Typography>
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Bid Setup & Management"
                        disableView
                        disableCreate
                        disableComment
                        disableReview
                        value={bidManagement ? SecurityPermissionLevel.NUMBER_4 : null}
                        setValue={(value) => setBidManagement(!!value)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Bid Submissions"
                        disableView
                        disableCreate
                        disableComment
                        disableReview
                        value={bidTabulation ? SecurityPermissionLevel.NUMBER_4 : null}
                        setValue={(value) => setBidTabulation(!!value)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Planholder List"
                        disableCreate
                        disableComment
                        disableReview
                        disableAdmin
                        value={planholderList ? SecurityPermissionLevel.NUMBER_0 : null}
                        setValue={(value) => setPlanholderList(value !== null)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Bid Drawings"
                        disableReview
                        value={bidDrawings}
                        setValue={setBidDrawings}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Project Manual"
                        disableReview
                        value={projectManual}
                        setValue={setProjectManual}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Addenda"
                        disableReview
                        value={addenda}
                        setValue={setAddenda}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Informational Items"
                        disableReview
                        value={informationalItems}
                        setValue={setInformationalItems}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Substitution Requests"
                        value={substitutionRequests}
                        setValue={setSubstitutionRequests}
                      />
                      <PermissionControl
                        label="Bidder RFIs"
                        value={bidderRFIs}
                        disabled={mode === FormMode.NONE}
                        setValue={setBidderRFIs}
                      />
                      {/*<PermissionControl*/}
                      {/*  disabled={mode === FormMode.NONE}*/}
                      {/*  label="Regulatory Approvals"*/}
                      {/*  disableReview*/}
                      {/*  value={regulatoryApprovals}*/}
                      {/*  setValue={setRegulatoryApprovals}*/}
                      {/*/>*/}

                      <Divider
                        style={{ marginTop: 16, marginBottom: 16, opacity: isMobile ? 0 : 100 }}
                      />
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Construction
                      </Typography>

                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Construction Management"
                        disableView
                        disableCreate
                        disableComment
                        disableReview
                        value={constructionManagement ? SecurityPermissionLevel.NUMBER_4 : null}
                        setValue={(value) => setConstructionManagement(value !== null)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Publishing and Conforming Center"
                        disableView
                        disableCreate
                        disableComment
                        disableReview
                        value={publishingCenter ? SecurityPermissionLevel.NUMBER_4 : null}
                        setValue={(value) => setPublishingCenter(value !== null)}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Drawings"
                        disableReview
                        value={drawings}
                        setValue={setDrawings}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Specifications"
                        disableReview
                        value={specifications}
                        setValue={setSpecifications}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="ASI Documents"
                        disableReview
                        value={ASIDocuments}
                        setValue={setASIDocuments}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Submittals"
                        value={submittals}
                        setValue={setSubmittals}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Requests for Information"
                        value={requestsForInformation}
                        setValue={setRequestsForInformation}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Change Orders"
                        disableReview
                        value={changeOrders}
                        setValue={setChangeOrders}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Const. Change Directives"
                        disableReview
                        value={constChangeDirectives}
                        setValue={setConstChangeDirectives}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Potential Change Orders"
                        value={potentialChangeOrders}
                        setValue={setPotentialChangeOrders}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Requests for Change"
                        value={requestsForChange}
                        setValue={setRequestsForChange}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Work Change Proposal Req."
                        value={workChangeProposalReq}
                        setValue={setWorkChangeProposalReq}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Pay Applications"
                        value={payApplications}
                        setValue={setPayApplications}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Contractor Daily Logs"
                        disableReview
                        value={contractorDailyLogs}
                        setValue={setContractorDailyLogs}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Field Reports"
                        disableReview
                        value={fieldReports}
                        setValue={setFieldReports}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Meeting Minutes"
                        disableReview
                        value={meetingMinutes}
                        setValue={setMeetingMinutes}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Miscellaneous Documents"
                        disableReview
                        value={miscellaneousDocuments}
                        setValue={setMiscellaneousDocuments}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Schedules"
                        disableReview
                        value={schedules}
                        setValue={setSchedules}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Testing"
                        disableReview
                        value={testing}
                        setValue={setTesting}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Consultant Review"
                        value={additionalReview}
                        setValue={setAdditionalReview}
                      />

                      <Divider
                        style={{ marginTop: 16, marginBottom: 16, opacity: isMobile ? 0 : 100 }}
                      />
                      <Typography variant="subtitle2" style={{ paddingBottom: 16 }}>
                        Close Out
                      </Typography>

                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Punch List"
                        value={punchList}
                        setValue={setPunchList}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="As-Built"
                        value={asBuilt}
                        setValue={setAsBuilt}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Closeout Submittals"
                        value={closeoutSubmittals}
                        setValue={setCloseoutSubmittals}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="O&M Data"
                        disableReview
                        value={OMData}
                        setValue={setOMData}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Warranty Items"
                        value={warrantyItems}
                        setValue={setWarrantyItems}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Substantial Completion Cert."
                        disableReview
                        value={substantialCompletionCert}
                        setValue={setSubstantialCompletionCert}
                      />
                      <PermissionControl
                        disabled={mode === FormMode.NONE}
                        label="Clear L&P"
                        disableReview
                        value={clearLP}
                        setValue={setClearLP}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Card>
        </Grid>
      </Grid>
      <DeleteSecurityGroupDialog
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        transferAndDelete={transferUsersAndDelete}
        securityGroups={securityGroups.filter((sg) => sg.id !== selectedGroupId)}
        summary={securityGroupSummary}
      />
    </main>
  );
}
