import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Auth } from '@aws-amplify/auth';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../../features/user/selectors';
import { requestEmailChange } from '../../../models/api/users';
import { addSnackbar } from '../../../features/snackbar/actions';
import isEmail = validator.isEmail;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

export default function SettingsPasswordForm() {
  const classes = useStyles();

  const user = useSelector(getUserState);

  const dispatch = useDispatch();

  const [snackOpen, setSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);

  const [email, setEmail] = useState(user.email);
  const [hasRequestedEmailChange, setHasRequestedEmailChange] = useState(false);

  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>();
  const [newPasswordError, setNewPasswordError] = useState<string>();

  useEffect(() => {
    setEmail(user.email);
  }, [user]);

  const handleSubmit = () => {
    let valid = true;
    if (newPassword !== newPasswordRepeat) {
      setNewPasswordError('Passwords do not match');
      valid = false;
    }
    if (valid) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword || '', newPassword || '');
        })
        .then((data) => {
          setSnackOpen(true);
          setNewPasswordError(undefined);
        })
        .catch((e) => setErrorSnackOpen(true));
    }
  };

  const handleRequestEmail = async () => {
    try {
      await requestEmailChange(user.id, email);
      setHasRequestedEmailChange(true);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span>Your email has not been changed yet.</span>
              <span>Please click the link in the email sent to {email}.</span>
            </div>
          ),
          severity: 'info',
          autoHideDuration: null,
        }),
      );
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Something went wrong while changing your account email.',
          severity: 'error',
        }),
      );
    }
  };

  return (
    <div className={classes.root} style={{ maxWidth: 500 }}>
      <input type="text" style={{ display: 'none' }} />
      <input type="password" style={{ display: 'none' }} />
      <Grid container spacing={2} justify="flex-start">
        <Grid item xs={11}>
          <Typography variant="caption">Email</Typography>
          <TextField
            autoComplete="off"
            variant="outlined"
            fullWidth
            color="primary"
            value={email}
            disabled={hasRequestedEmailChange}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid container>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disableElevation
              onClick={handleRequestEmail}
              disabled={!isEmail(email) || email === user.email || hasRequestedEmailChange}
              className={classes.margin}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={11} style={{ marginTop: 64 }}>
          <Typography variant="caption">Enter Old Password</Typography>
          <TextField
            id="old-password"
            label="Old password"
            type="password"
            name="old-password"
            variant="outlined"
            autoComplete="off"
            fullWidth
            color="primary"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="caption">Enter New Password</Typography>
          <TextField
            id="new-password"
            label="New password"
            type="password"
            name="new-password"
            variant="outlined"
            fullWidth
            error={newPasswordError !== undefined}
            helperText={newPasswordError}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            id="new-password-conf"
            label="Confirm Password"
            type="password"
            name="new-password-conf"
            variant="outlined"
            fullWidth
            value={newPasswordRepeat}
            onChange={(e) => setNewPasswordRepeat(e.target.value)}
            color="primary"
          />
        </Grid>
      </Grid>

      <div>
        <Grid container>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSubmit}
              disableElevation
              disabled={
                !oldPassword ||
                !newPassword ||
                !newPasswordRepeat ||
                newPassword !== newPasswordRepeat
              }
              className={classes.margin}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={snackOpen}
          autoHideDuration={2000}
          onClose={() => setSnackOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackOpen(false)} severity="success" variant="filled">
            Successfuly changed your password!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorSnackOpen}
          autoHideDuration={2000}
          onClose={() => setErrorSnackOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackOpen(false)} severity="success" variant="filled">
            Successfuly changed your password!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
