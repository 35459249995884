import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Add, HighlightOffRounded } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { CancelButton, SubmitButton } from '../custom-components/CustomButtons';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { FileNode, IUser } from '../../api-client/autogenerated';
import { getGroupsState } from '../../features/groups/selector';
import {
  copyToClipboard,
  createQRCode,
  getFilteredUsersFromUserGroup,
  getPageTitle,
} from '../../scripts/utils';
import SelectCompanyUsersDialog from '../dialogs/SelectCompanyUsersDialog';
import Button from '@material-ui/core/Button';
import { getUserState } from '../../features/user/selectors';
import {
  getUsersAndUserGroupsFromFileNodes,
  ManagePermissionsDialogType,
} from './ManagePermissionsDialog';
import { notifyUsersOfAccessToDesignFile } from '../../models/api/filesystem';
import DistributionList from '../form-pages/user-groups/DistributionList';
import _ from 'lodash';
import CircularLoader from '../loader/CircularLoader';
import validator from 'validator';
import DividerWithText from '../custom-components/DividerWithText';
import { addSnackbar } from '../../features/snackbar/actions';
import { getDocumentState } from '../../features/document/selectors';
import { getDocumentsType } from '../../features/documents/selectors';
import { isMobile } from 'react-device-detect';

const isEmail = validator.isEmail;

interface EmailUsersDialogProps {
  dialogOpen: boolean;
  files?: FileNode[];
  closeDialog: () => void;
  isDesignNotify?: boolean;
  onSubmit?: (emails: string[], note?: string) => Promise<void>;
  defaultSubject?: string;
  defaultDescription?: string;
  handleCopy?: () => void;
  disableSnackbar?: boolean;
  getLink?: () => Promise<string>;
}

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    textTransform: 'none',
    color: '#464546',
    maxWidth: '600px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: '#0947B9',
    fontWeight: 500,
    fontSize: '1.125rem',
    marginRight: 15,
    marginBottom: 25,
  },
  companyText: {
    fontSize: '20px',
    lineHeight: '32px',
    textTransform: 'none',
    color: 'gray',
    fontWeight: 500,
  },
  userText: {
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    color: 'gray',
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    fontSize: '1em',
  },
});

export default function EmailUsersDialog(props: EmailUsersDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    closeDialog,
    files,
    isDesignNotify,
    onSubmit,
    defaultSubject,
    defaultDescription,
    disableSnackbar,
    getLink,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const currentDocument = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);
  const project = useSelector(getProjectState);
  const groups = useSelector(getGroupsState);

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));

  const qrcodeRef = useRef<HTMLDivElement>(null);

  const [inputSubject, setInputSubject] = useState<string>();
  const [inputDescription, setInputDescription] = useState<string>();

  const [selectRecipientsDialogOpen, setSelectRecipientsDialogOpen] = useState(false);

  const [eligibleUserIds, setEligibleUserIds] = useState<string[]>([]);
  const [eligibleUserGroupIds, setEligibleUserGroupIds] = useState<string[]>([]);

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const [emails, setEmails] = useState<string[]>([]);
  const [inputEmail, setInputEmail] = useState('');
  const [error, setError] = useState('');

  const [link, setLink] = useState('');
  const [qrcodeCreated, setQrcodeCreated] = useState(false);
  const [qrcodeLinkElement, setQrcodeLinkElement] = useState<HTMLAnchorElement>();

  const [willSendToSelf, setWillSendToSelf] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (files) {
      const { userIds, userGroupIds } = getUsersAndUserGroupsFromFileNodes(files);
      setEligibleUserIds(userIds);
      setEligibleUserGroupIds(userGroupIds);
    }
  }, [files]);

  useEffect(() => {
    if (defaultSubject) setInputSubject(defaultSubject);
  }, [defaultSubject]);

  useEffect(() => {
    if (defaultDescription) setInputDescription(defaultDescription);
  }, [defaultDescription]);

  const setUserAndGroupIds = (userIds: string[], groupIds: string[]) => {
    setSelectedUserIds(userIds);
    setSelectedGroupIds(groupIds);
  };

  const getUsers = () => {
    let users = [] as IUser[];
    selectedUserIds.forEach((userId) => {
      const projectUser = project?.projectUserList?.find((pUser) => pUser.userId === userId);
      if (projectUser?.user) users.push(projectUser.user);
    });
    selectedGroupIds.forEach((groupId) => {
      const group = groups.find((group) => group.id === groupId);
      if (group) {
        users.push(...getFilteredUsersFromUserGroup(ManagePermissionsDialogType.Design, group));
      }
    });
    if (willSendToSelf) users.push(user);
    return _.uniqBy(users, (user) => user.id);
  };

  const getEmails = () => {
    if (willSendToSelf) return [user.email, ...emails];
    return emails;
  };

  const handleAddEmail = (email: string, event?: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!event || event.key === 'Enter') && inputEmail) {
      if (!isEmail(inputEmail)) {
        setError('Invalid email');
      } else if (emails.includes(inputEmail)) {
        setError('Email already exists');
      } else {
        setEmails((prev) => [...prev, inputEmail]);
        setInputEmail('');
      }
    }
  };

  const handleSubmit = async () => {
    closeDialog();
    if (isDesignNotify) {
      await handleNotifyUsers();
    } else if (onSubmit) {
      await onSubmit(getEmails(), inputDescription);
    }
    if (!disableSnackbar) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Successfully sent email(s)!',
          severity: 'success',
        }),
      );
    }
  };

  const handleNotifyUsers = async () => {
    if (!project || !inputSubject || !files) return;
    try {
      setIsSubmitting(true);
      await notifyUsersOfAccessToDesignFile(
        project.id,
        files.map((f) => ({ ownerId: f.ownerId, projectId: f.projectId, fullKey: f.fullKey })),
        inputSubject,
        willSendToSelf ? [...selectedUserIds, user.id] : selectedUserIds,
        selectedGroupIds,
        inputDescription,
      );
      setIsSubmitting(false);
      closeDialog();
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (dialogOpen && getLink) {
      getLink().then((link) => setLink(link));
    }

    if (!dialogOpen) {
      setLink('');
      setQrcodeCreated(false);
    }
  }, [dialogOpen]);

  useEffect(() => {
    if (link && qrcodeRef.current && !qrcodeCreated) {
      const hasSubtitle = !!(docType && currentDocument);
      createQRCode({
        qrcodeRef: qrcodeRef.current,
        link,
        quietZone: 16,
        title: project?.name,
        subTitle: hasSubtitle ? getPageTitle(docType!, currentDocument) : undefined,
        onRenderingEnd: (options, url) => {
          const link = document.createElement('a');
          link.href = url;
          link.download = 'qrcode.png';
          setQrcodeLinkElement(link);
        },
        drawer: 'canvas',
      });
      setQrcodeCreated(true);
    }
  }, [link]);

  const handleCopy = async () => {
    if (link) {
      try {
        await copyToClipboard(link);
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Link copied to clipboard',
            severity: 'success',
          }),
        );
      } catch {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Error copying link',
            severity: 'error',
          }),
        );
      }
    } else {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Error retrieving link',
          severity: 'error',
        }),
      );
    }
  };

  const handleDownloadQrcode = () => {
    if (qrcodeLinkElement) {
      qrcodeLinkElement.click();
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      onContextMenu={(e) => e.stopPropagation()}
      maxWidth="md"
      fullScreen={isXs}
      PaperProps={isXs ? undefined : { style: { minWidth: 600, maxHeight: '720px' } }}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          {isDesignNotify ? 'Create Notification Email' : 'Send A Link'}
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={closeDialog}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 16, paddingTop: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '16px 0px 8px 0px' }}>
          {isDesignNotify ? (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Add />}
              onClick={() => setSelectRecipientsDialogOpen(true)}
              style={{ width: '35%', whiteSpace: 'nowrap' }}
            >
              Add Recipients
            </Button>
          ) : (
            <>
              <Typography style={{ marginBottom: '12px' }}>
                Input a valid email address, then press the <strong>Enter</strong> key or click{' '}
                <strong>Add</strong>.
              </Typography>
              <Typography style={{ color: 'red', marginBottom: '6px' }}>{error}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email Address"
                  value={inputEmail}
                  onChange={(e) => {
                    setError('');
                    setInputEmail(e.target.value);
                  }}
                  onKeyDown={(e) => handleAddEmail(inputEmail, e)}
                  style={{ flexShrink: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => handleAddEmail(inputEmail)}
                  style={{ marginLeft: 8, padding: '0px 12px' }}
                >
                  Add
                </Button>
              </div>
            </>
          )}
          <FormControlLabel
            control={
              <GreenCheck
                value={willSendToSelf}
                onChange={(e, checked) => setWillSendToSelf(checked)}
              />
            }
            label={<Typography>Send a copy of this notification to me</Typography>}
            style={{ marginTop: 8 }}
          />
        </div>
        <Divider variant="fullWidth" style={{ marginBottom: 16 }} />
        <div
          style={{
            marginBottom: 16,
            paddingTop: 0,
            width: '100%',
            overflowY: 'auto',
            maxHeight: '400px',
            display: 'flex',
          }}
        >
          <Typography className={classes.label} style={{ marginBottom: 0, marginTop: 6 }}>
            To:
          </Typography>
          {selectedGroupIds.length === 0 &&
          selectedUserIds.length === 0 &&
          !willSendToSelf &&
          emails.length === 0 ? (
            <Typography variant="body2" style={{ marginBottom: 0, marginTop: 6 }}>
              {isDesignNotify
                ? 'There are no users currently selected to be notified'
                : 'No recipients'}
            </Typography>
          ) : (
            <>
              {isDesignNotify ? (
                <DistributionList open limitHeight users={getUsers()} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: 32,
                  }}
                >
                  {getEmails().map((email, index) => (
                    <Typography key={email + index}>{email}</Typography>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <Divider variant="fullWidth" style={{ marginBottom: 16 }} />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 25 }}>
            <Typography className={classes.label} style={{ marginBottom: 0 }}>
              From:
            </Typography>
            <div style={{ display: 'block', marginLeft: 8 }}>
              <Typography className={classes.companyText}>{user.company?.name}</Typography>
              <Typography className={classes.userText}>{user.name}</Typography>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.label}>Project Name:</Typography>
            <Typography>{project?.name}</Typography>
          </div>
          {isDesignNotify && (
            <div style={{ display: 'flex' }}>
              <Typography className={classes.label}>File/Folder:</Typography>
              <div style={{ marginBottom: 25 }}>
                {files?.map((file) => (
                  <Typography key={file.fullKey} style={{ fontWeight: 500, marginBottom: 4 }}>
                    {file.relativeKey}
                  </Typography>
                ))}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 25 }}>
            <Typography className={classes.label} style={{ marginBottom: 0 }}>
              Subject:
            </Typography>
            <TextField
              label="Subject (max 100 characters)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={!!defaultSubject}
              value={inputSubject}
              onChange={(e) => setInputSubject(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          {isDesignNotify && (
            <div style={{ display: 'flex' }}>
              <Typography className={classes.label} style={{ marginBottom: 0 }}>
                Note:
              </Typography>
              <TextField
                label="Note (optional)"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={inputDescription}
                onChange={(e) => setInputDescription(e.target.value)}
                InputProps={{ style: { padding: 10 } }}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions
        style={{ display: 'flex', justifyContent: 'space-between', padding: 24, paddingBottom: 8 }}
      >
        <CancelButton onClick={closeDialog} />
        {!isSubmitting ? (
          <SubmitButton
            disabled={
              !inputSubject ||
              (selectedGroupIds.length === 0 && selectedUserIds.length === 0 && emails.length === 0)
            }
            onClick={handleSubmit}
            style={{ padding: '0px 16px' }}
          >
            Send Emails
          </SubmitButton>
        ) : (
          <CircularLoader size={30} />
        )}
      </DialogActions>
      {getLink && (
        <div style={{ padding: '0px 24px' }}>
          <DividerWithText>OR</DividerWithText>
          <Button
            onClick={handleCopy}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Copy Link
          </Button>
          <DividerWithText>OR</DividerWithText>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!qrcodeLinkElement}
            onClick={handleDownloadQrcode}
            className={classes.submit}
          >
            Download QR Code
          </Button>
          <div ref={qrcodeRef} style={{ display: 'none' }} />
        </div>
      )}
      <SelectCompanyUsersDialog
        open={selectRecipientsDialogOpen}
        handleClose={() => setSelectRecipientsDialogOpen(false)}
        existingUserIds={[]}
        defaultSelectedUserIds={selectedUserIds}
        eligibleUserIds={eligibleUserIds}
        eligibleUserGroupIds={eligibleUserGroupIds}
        submit={setUserAndGroupIds}
        type={ManagePermissionsDialogType.Design}
      />
    </Dialog>
  );
}
