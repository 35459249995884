import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/BuildRounded';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';

const SubscriberAdminButton: React.FC = () => {
  const history = useHistory();

  const user = useSelector(getUserState);

  const handleClick = () => {
    if (!user.adminOfSubscriberId) return;
    history.push(`/main/subscriber-admin/${user.adminOfSubscriberId}`);
  };

  return (
    <IconButton style={{ padding: '4px', marginLeft: '8px' }} onClick={handleClick}>
      <BuildIcon style={{ height: '21px', color: '#7A797A' }} />
    </IconButton>
  );
};

export default SubscriberAdminButton;
