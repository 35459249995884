import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NavAppbar from '../nav-top/NavAppbar';
import { Button, Grid, Hidden, MenuItem, Select, Typography } from '@material-ui/core';
import PageTitle from '../page-title/PageTitle';
import CreatePunchListPageItem, { Image, Mode, PunchListItem } from './CreatePunchListPageItem';
import { Add } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import {
  getDocumentById,
  getDocumentByKey,
  insertDocument,
  modifyDocumentByIdWithResponse,
} from '../../models/api/documents';
import {
  ActionTakenType,
  DocumentTemplateType,
  INumberedDocumentView,
  IPhysicalBuilding,
  IPhysicalFloor,
  IPhysicalLocation,
  IRestrictedDocumentPatch,
  IUser,
  IUserGroup,
  PunchListStatusType,
  SecurityPermissionLevel,
} from '../../api-client/autogenerated';
import { getTemplates } from '../../models/api/templates';
import { getUserState } from '../../features/user/selectors';
import CircularLoader from '../loader/CircularLoader';
import dayjs from 'dayjs';
import { updateFile } from '../../models/api/filesystem';
import { useHistory } from 'react-router-dom';
import {
  DocumentOrLocationMatchParams,
  documentTypeToUrl,
  fileIsImage,
  getPreviousDocumentRevisions,
  getFileBlob,
  getImageByFileId,
  PublicDocumentMatchParams,
} from '../../scripts/utils';
import { ascendingComparator, descendingComparator } from '../document-index/DocumentIndexUtils';
import { useParams } from 'react-router';
import {
  fetchDocumentsByType,
  reloadDocuments,
  setDocumentsType,
} from '../../features/documents/actions';
import { setDocument } from '../../features/document/actions';
import _ from 'lodash';
import { getDocumentsState } from '../../features/documents/selectors';
import { getDocumentsLoadingState } from '../../features/loading/selectors';
import { getDocPermission } from '../../scripts/store-utils';
import FullscreenLoader from '../loader/FullscreenLoader';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 280,
  },
  paper: {
    color: theme.palette.text.secondary,
    width: '100%',
    position: 'relative',
  },
  form: {
    marginTop: 8,
    paddingBottom: 32,
  },
  title: {
    padding: 0,
  },
  textField: {},
  labelText: { marginBottom: 8 },
  warningText: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'italic',
    color: '#000000',
    marginTop: 8,
  },
  bottomContainer: {
    width: '100%',
    marginTop: 32,
  },
  divider: { paddingTop: 0, paddingBottom: 0 },
}));

export const getPunchListItemFromDocument = async (
  document: INumberedDocumentView,
  isPublic = false,
): Promise<PunchListItem> => {
  const {
    id,
    creatorUserId,
    files,
    dateOfObservation,
    description: generalObservation,
    cost,
    currentWorkInProgress,
    punchListStatus,
    markedLocation,
    isDraft,
    customContractor,
    revisionNumber,
    documentUserList,
    documentUserGroupList,
    comments,
    actionTaken,
    isHidden,
  } = document;

  let images = [] as Image[];

  if (files?.length) {
    images = (
      await Promise.all(
        files
          .filter((f) => fileIsImage(f))
          .map(async (file) => {
            try {
              const image = await getFileBlob(file.id);
              if (image)
                return {
                  file: new File([image], file.name),
                  image: URL.createObjectURL(image),
                  description: file.description || '',
                  new: false,
                  databaseFile: file,
                };
              return null;
            } catch (e: any) {
              return {
                description: file.description || '',
                new: false,
                databaseFile: file,
                error: true,
              };
            }
          }),
      )
    ).filter((e) => e) as Image[];
  }

  return {
    id: id,
    locationId: document.physicalLocationId || '',
    dateOfObservation: dateOfObservation ? dayjs(dateOfObservation) : dayjs(),
    postedDate: dayjs(document.createdOn),
    lastModified: dayjs(document.updatedOn),
    generalObservation: generalObservation || '',
    responsibleSubcontractor: customContractor || '',
    cost: cost ?? null,
    workInProgress: currentWorkInProgress || '',
    status: punchListStatus || PunchListStatusType.New,
    markedLocation: markedLocation || null,
    isDraft: isDraft || false,
    isHidden: !!isHidden,
    creatorUserId,
    images,
    comments: comments || [],
    associatedUserIds: isPublic ? [] : documentUserList?.map((docUser) => docUser.userId) || [],
    associatedUserGroupIds: isPublic
      ? []
      : documentUserGroupList?.map((docUserGroup) => docUserGroup.userGroupId) || [],
    associatedUsers: isPublic
      ? (documentUserList?.map((docUser) => docUser.user).filter((u) => u) as IUser[]) || []
      : [],
    associatedUserGroups: isPublic
      ? (documentUserGroupList
          ?.map((docUserGroup) => docUserGroup.userGroup)
          .filter((ug) => ug) as IUserGroup[]) || []
      : [],
    revisionNumber: revisionNumber || 0,
    error: false,
    uploading: false,
    withdrawn: actionTaken === ActionTakenType.Withdrawn,
  };
};

type Props = {
  mode: Mode;
};

export default function CreatePunchListPage(props: Props) {
  const classes = useStyles();
  const { mode } = props;

  const history = useHistory<{
    selectedBuildingId: string;
    selectedFloorId: string;
    selectedLocationId: string;
  }>();
  const dispatch = useDispatch();
  const params = useParams<DocumentOrLocationMatchParams>();
  const publicParams = useParams<PublicDocumentMatchParams>();
  const selectedProject = useSelector(getProjectState);
  const user = useSelector(getUserState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const locations = useSelector(getPhysicalLocationsState);
  const floors = useSelector(getPhysicalFloorsState);
  const documents = useSelector(getDocumentsState);
  const documentsLoading = useSelector(getDocumentsLoadingState);

  const permission = getDocPermission();

  const [publicBuildingOverride, setPublicBuildingOverride] = useState<IPhysicalBuilding>();
  const [publicFloorOverride, setPublicFloorOverride] = useState<IPhysicalFloor>();
  const [publicLocationOverride, setPublicLocationOverride] = useState<IPhysicalLocation>();

  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingNewDraft, setIsCreatingNewDraft] = useState(false);

  // ITEMS
  const [punchListItems, setPunchListItems] = useState<PunchListItem[]>([]);

  // FORM SUBMIT
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittingText, setSubmittingText] = useState<string>('');

  const [locationImageFile, setLocationImageFile] = useState<string>();

  const [originalDocuments, setOriginalDocuments] = useState<INumberedDocumentView[]>([]);

  const isEditMode = mode === 'EDIT_ITEM' || mode === 'EDIT_LOCATION';
  const isEditingDrafts =
    (mode === 'EDIT_ITEM' || mode === 'EDIT_LOCATION') &&
    punchListItems.every((item) => item.isDraft);
  const isPublic = mode === 'PUBLIC';

  const selectedBuilding = buildings.find((b) => b.id === selectedBuildingId);

  useEffect(() => {
    if (history.location.state) {
      const { selectedBuildingId, selectedFloorId, selectedLocationId } = history.location.state;
      if (selectedBuildingId && selectedFloorId && selectedLocationId) {
        setSelectedBuildingId(selectedBuildingId);
        setSelectedFloorId(selectedFloorId);
        setSelectedLocationId(selectedLocationId);
        createNewDraft(selectedLocationId);
      }
    }
  }, [history.location.state]);

  const createNewDraft = async (locationId: string) => {
    if (!selectedProject) return;
    setIsCreatingNewDraft(true);
    try {
      const templates = await getTemplates();

      const document = await insertDocument({
        documentTemplateId: templates[DocumentTemplateType.PunchList],
        projectId: selectedProject.id,
        creatorUserId: user.id,
        lastUpdatedByUserId: user.id,
        physicalLocationId: locationId,
        isDraft: true,
      });

      const newPunchListItem = await getPunchListItemFromDocument(document);

      setPunchListItems((prev) => [...prev, newPunchListItem]);
      setOriginalDocuments((prev) => [...prev, document]);
    } finally {
      setIsCreatingNewDraft(false);
      setIsLoading(false);
    }
  };

  const fetchCreatedDocument = async (documentId: string) => {
    setIsLoading(true);
    const originalDocument = await getDocumentById(documentId);
    dispatch(setDocument(originalDocument));
    const originalDocumentItem = await getPunchListItemFromDocument(originalDocument);

    const revisions = getPreviousDocumentRevisions(originalDocument);
    const revisionItems = await Promise.all(
      revisions.map((revision) => getPunchListItemFromDocument(revision)),
    );

    setOriginalDocuments([originalDocument, ...revisions]);
    setPunchListItems(
      [originalDocumentItem, ...revisionItems].sort((a, b) =>
        descendingComparator(a, b, 'revisionNumber'),
      ),
    );

    const itemLocation = locations.find(
      (location) => location.id === originalDocument.physicalLocationId,
    );
    if (itemLocation) {
      setSelectedFloorId(itemLocation.floorId);
      setSelectedLocationId(itemLocation.id);
      const selectedFloor = floors.find((floor) => floor.id === itemLocation.floorId);
      if (selectedFloor) setSelectedBuildingId(selectedFloor.buildingId);
    }
    setIsLoading(false);
  };

  const fetchDocumentsFromLocation = async (locationId: string) => {
    setIsLoading(true);
    const documentsInLocation = documents.filter((doc) => doc.physicalLocationId === locationId);
    const items = await Promise.all(
      documentsInLocation.map((doc) => getPunchListItemFromDocument(doc)),
    );

    setPunchListItems(items);
    setOriginalDocuments(documentsInLocation);

    const itemLocation = locations.find((location) => location.id === locationId);
    if (itemLocation) {
      setSelectedFloorId(itemLocation.floorId);
      setSelectedLocationId(itemLocation.id);
      const selectedFloor = floors.find((floor) => floor.id === itemLocation.floorId);
      if (selectedFloor) setSelectedBuildingId(selectedFloor.buildingId);
    }
    setIsLoading(false);
  };

  const fetchPublicDocument = async (key: string) => {
    setIsLoading(true);
    const { document } = await getDocumentByKey(key);

    setPunchListItems([await getPunchListItemFromDocument(document, true)]);

    if (document.physicalLocation?.floor?.building) {
      if (document.physicalLocation.locationImageFileId)
        getImageByFileId(document.physicalLocation.locationImageFileId, true).then(
          setLocationImageFile,
        );

      setPublicBuildingOverride(document.physicalLocation.floor.building);
      setPublicFloorOverride(document.physicalLocation.floor);
      setPublicLocationOverride(document.physicalLocation);

      setSelectedBuildingId(document.physicalLocation.floor.buildingId);
      setSelectedFloorId(document.physicalLocation.floorId);
      setSelectedLocationId(document.physicalLocation.id);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (mode === 'EDIT_ITEM' && params.documentId) {
      dispatch(setDocumentsType(DocumentTemplateType.PunchList));
      fetchCreatedDocument(params.documentId);
    }
  }, [mode, params]);

  useEffect(() => {
    if (mode === 'EDIT_LOCATION' && params.locationId && selectedProject) {
      dispatch(setDocumentsType(DocumentTemplateType.PunchList));
      if (!documentsLoading && documents.length === 0) {
        dispatch(fetchDocumentsByType(selectedProject.id, DocumentTemplateType.PunchList));
      }
      fetchDocumentsFromLocation(params.locationId);
    }
  }, [mode, selectedProject, documents, documentsLoading, params]);

  useEffect(() => {
    if (isPublic && publicParams.key) {
      fetchPublicDocument(publicParams.key);
    }
  }, [mode]);

  const getBuildingOptions = () => {
    if (isPublic && publicBuildingOverride)
      return [
        <MenuItem key={publicBuildingOverride.id} value={publicBuildingOverride.id}>
          {publicBuildingOverride.name}
        </MenuItem>,
      ];
    return [
      <MenuItem key="empty" value="" disabled>
        <span style={{ color: '#BFBFBF' }}>Building</span>
      </MenuItem>,
      buildings
        .filter((building) =>
          floors
            .filter((floor) => floor.buildingId === building.id)
            .some((floor) => floor.floorPlanImageFileId || building.isDefault),
        )
        .map((building) => (
          <MenuItem key={building.id} value={building.id}>
            {building.name}
          </MenuItem>
        )),
    ];
  };

  const getFloorOptions = () => {
    if (isPublic && publicFloorOverride)
      return [
        <MenuItem key={publicFloorOverride.id} value={publicFloorOverride.id}>
          {publicFloorOverride.name}
        </MenuItem>,
      ];
    return [
      <MenuItem key="empty" value="" disabled>
        <span style={{ color: '#BFBFBF' }}>Floor</span>
      </MenuItem>,
      floors
        .filter((floor) => floor.buildingId === selectedBuildingId)
        .sort((a, b) => ascendingComparator(a, b, 'index'))
        .map((floor) => (
          <MenuItem key={floor.id} disabled={!floor.floorPlanImageFileId} value={floor.id}>
            {floor.name}
          </MenuItem>
        )),
    ];
  };

  const getLocationOptions = () => {
    if (isPublic && publicLocationOverride)
      return [
        <MenuItem key={publicLocationOverride.id} value={publicLocationOverride.id}>
          {publicLocationOverride.name}
        </MenuItem>,
      ];
    return [
      <MenuItem key="empty" value="" disabled>
        <span style={{ color: '#BFBFBF' }}>Location</span>
      </MenuItem>,
      locations
        .filter((location) => location.floorId === selectedFloorId)
        .sort((a, b) => ascendingComparator(a, b, 'name'))
        .map((location) => (
          <MenuItem key={location.id} value={location.id}>
            {location.name}
          </MenuItem>
        )),
    ];
  };

  const handleSaveLocation = async () => {
    try {
      dispatch(blockNavigation());
      setIsSubmitting(true);
      for (let i = 0; i < punchListItems.length; i += 1) {
        const item = punchListItems[i];
        setSubmittingText(
          `Updating ${item.generalObservation ? item.generalObservation : 'Draft Item'}...`,
        );
        await saveItem(item, item.isDraft);
      }

      dispatch(allowNavigation());
      dispatch(reloadDocuments());
      history.push(
        `/main/projects/${selectedProject!.id}/documents/${
          documentTypeToUrl[DocumentTemplateType.PunchList]
        }`,
      );
    } finally {
      setIsSubmitting(false);
      setSubmittingText('');
      dispatch(allowNavigation());
    }
  };

  const saveItem = async (item: PunchListItem, isDraft: boolean) => {
    const originalDocument = originalDocuments.find((doc) => doc.id === item.id);
    if (!originalDocument) return;

    if (item.markedLocation) {
      // @ts-ignore
      delete item.markedLocation.lastUpdatedByUserId;
    }

    const patch: IRestrictedDocumentPatch = {
      dateOfObservation:
        item.dateOfObservation?.format('YYYY-MM-DD') !== originalDocument.dateOfObservation
          ? item.dateOfObservation?.format('YYYY-MM-DD')
          : undefined,
      description:
        item.generalObservation.trim() !== originalDocument.description
          ? item.generalObservation.trim()
          : undefined,
      customContractor:
        item.responsibleSubcontractor.trim() !== originalDocument.customContractor
          ? item.responsibleSubcontractor.trim()
          : undefined,
      markedLocation: !_.isEqual(item.markedLocation, originalDocument.markedLocation)
        ? item.markedLocation
        : undefined,
      cost: item.cost !== originalDocument.cost ? item.cost : undefined,
      currentWorkInProgress:
        !!item.workInProgress.trim() &&
        item.workInProgress.trim() !== originalDocument.currentWorkInProgress
          ? item.workInProgress.trim()
          : undefined,
      punchListStatus: item.status !== originalDocument.punchListStatus ? item.status : undefined,
      isDraft,
    };

    const usersToAdd = item.associatedUserIds.filter(
      (associatedUserId) =>
        !originalDocument.documentUserList?.some((docUser) => associatedUserId === docUser.userId),
    );

    const usersToRemove =
      originalDocument.documentUserList
        ?.filter(
          (docUser) =>
            !item.associatedUserIds.some((associatedUserId) => docUser.userId === associatedUserId),
        )
        ?.map((docUser) => docUser.userId) || [];

    const groupsToAdd = item.associatedUserGroupIds.filter(
      (id) =>
        !originalDocument.documentUserGroupList?.some(({ userGroupId }) => userGroupId === id),
    );

    const groupsToRemove =
      originalDocument.documentUserGroupList
        ?.filter(({ userGroupId }) => !item.associatedUserGroupIds.some((id) => id === userGroupId))
        .map(({ userGroupId }) => userGroupId) || [];

    const changedImages = item.images.filter((i) => i.changed);

    if (changedImages.length > 0) {
      await Promise.all(
        changedImages.map((file) =>
          updateFile(originalDocument.id, file.databaseFile.id, {
            description: file.description,
          }),
        ),
      );
    }

    const { updatedDocument } = await modifyDocumentByIdWithResponse(originalDocument.id, {
      patch,
      followers: {
        addUserIds: usersToAdd,
        removeUserIds: usersToRemove,
        addUserGroupIds: groupsToAdd,
        removeUserGroupIds: groupsToRemove,
      },
    });

    return updatedDocument;
  };

  const handleSaveSingleItem = async (item: PunchListItem, isDraft: boolean) => {
    try {
      dispatch(blockNavigation());
      setIsSubmitting(true);

      setSubmittingText(
        `${isDraft ? 'Saving' : 'Publishing'} ${
          item.generalObservation ? item.generalObservation : 'Draft Item'
        }...`,
      );
      const updatedDocument = await saveItem(item, isDraft);

      if (updatedDocument) {
        const index = punchListItems.findIndex((x) => x.id === updatedDocument.id);
        if (index !== -1) {
          const updatedItem = await getPunchListItemFromDocument(updatedDocument);
          setPunchListItems((prev) => [
            ...prev.slice(0, index),
            updatedItem,
            ...prev.slice(index + 1),
          ]);
        }
      }

      dispatch(allowNavigation());
      dispatch(reloadDocuments());
    } finally {
      setIsSubmitting(false);
      setSubmittingText('');
      dispatch(allowNavigation());
    }
  };

  const getPageTitle = () => {
    if (isPublic) return 'View Punch List Item';
    if (mode === 'CREATE' || isEditingDrafts) return 'Create Punch List Item(s)';
    return 'Edit Punch List Item(s)';
  };

  const getPublishDisabled = () => {
    return (
      isSubmitting ||
      punchListItems.some(
        (item) =>
          (!item.isDraft &&
            (!item.generalObservation ||
              !item.dateOfObservation ||
              (!item.markedLocation && !selectedBuilding?.isDefault))) ||
          item.uploading,
      ) ||
      (isEditMode &&
        !isEditingDrafts &&
        (!permission || permission < SecurityPermissionLevel.NUMBER_2))
    );
  };

  useEffect(() => {
    setLocationImageFile(undefined);
    if (mode === 'CREATE') {
      setPunchListItems((prev) =>
        prev.map(({ markedLocation, ...rest }) => ({ markedLocation: null, ...rest })),
      );
    }
    if (selectedLocationId) {
      const locationObject = locations.find((l) => l.id === selectedLocationId);
      if (locationObject?.locationImageFileId) {
        getImageByFileId(locationObject.locationImageFileId, true).then(setLocationImageFile);
      }
    }
  }, [selectedLocationId]);

  const handlePrint = () => {
    if (mode === 'EDIT_LOCATION') {
      history.push(`print-location`);
    } else {
      history.push(`print`);
    }
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container>
        <Grid item xs>
          <PageTitle title={getPageTitle()} />
        </Grid>

        {isLoading ? (
          <FullscreenLoader />
        ) : (
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} container>
              <Grid container spacing={3} justify="center" style={{ maxWidth: 1024 }}>
                <Grid item md={3} xs={12}>
                  <div className={`${classes.column}`}>
                    <Typography variant="body1">Building</Typography>
                    <Select
                      variant="outlined"
                      value={selectedBuildingId}
                      disabled
                      displayEmpty
                      style={{ marginTop: 4 }}
                    >
                      {getBuildingOptions()}
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.column}>
                    <Typography variant="body1">Floor</Typography>
                    <Select
                      variant="outlined"
                      value={selectedFloorId}
                      disabled
                      displayEmpty
                      style={{ marginTop: 4 }}
                    >
                      {getFloorOptions()}
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.column}>
                    <Typography variant="body1">Location</Typography>
                    <Select
                      variant="outlined"
                      value={selectedLocationId}
                      disabled
                      displayEmpty
                      style={{ marginTop: 4 }}
                    >
                      {getLocationOptions()}
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div
                    className={classes.column}
                    style={{
                      height: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div />

                    {isEditMode ? (
                      <Hidden xsDown>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePrint}
                          style={{ whiteSpace: 'nowrap', marginTop: 18, maxWidth: 240 }}
                        >
                          {mode === 'EDIT_LOCATION' ? 'Export All Items' : 'Export Item'}
                        </Button>
                      </Hidden>
                    ) : null}

                    <div />
                  </div>
                </Grid>
              </Grid>

              {/* PUNCH LIST ITEMS */}
              {punchListItems.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div className={'start-new-page'} />
                  <CreatePunchListPageItem
                    parentData={punchListItems}
                    parentUpdate={setPunchListItems}
                    mode={mode}
                    originalDocument={
                      isEditMode ? originalDocuments.find((doc) => doc.id === item.id) : undefined
                    }
                    locationImageFile={locationImageFile}
                    index={index}
                    buildingName={publicBuildingOverride?.name}
                    floorName={publicFloorOverride?.name}
                    locationName={publicLocationOverride?.name}
                    saveItem={(isDraft: boolean) => handleSaveSingleItem(item, isDraft)}
                  />
                </React.Fragment>
              ))}

              {/* ADD / SAVE / PUBLISH */}
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: 16,
                    }}
                  >
                    {isSubmitting && <CircularLoader />}
                    {submittingText}
                  </div>
                </Grid>
                {!isEditMode && !isPublic && (
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={4}>
                      {!isCreatingNewDraft ? (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Add />}
                          onClick={() => createNewDraft(selectedLocationId)}
                          style={{ marginTop: 32, maxWidth: 325 }}
                        >
                          <span style={{ whiteSpace: 'nowrap' }}>
                            ADD ANOTHER ITEM IN THIS LOCATION
                          </span>
                        </Button>
                      ) : (
                        <CircularLoader style={{ marginTop: 8 }} />
                      )}
                    </Grid>
                  </Grid>
                )}
                {!isPublic && isEditMode && punchListItems.length > 1 ? (
                  <Grid container item xs={12} style={{ marginTop: 16 }}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={getPublishDisabled()}
                        onClick={handleSaveLocation}
                      >
                        Update Items
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </main>
  );
}
