import {
  Button,
  createStyles,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { Info, Visibility } from '@material-ui/icons';
import {
  blueHighlight,
  ConflictType,
  DCCRow,
  getSortPropertyFromPublishType,
  getUserFriendlyDocumentNameFromPublishType,
  greenHighlight,
  PublishType,
  TableType,
  yellowHighlight,
} from './DCCUtils';
import {
  addSpacingIncremental,
  ascendingComparator,
  removeSpacing,
} from '../document-index/DocumentIndexUtils';
import Add from '@material-ui/icons/Add';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import {
  ConformingCenterConflictResolutionType,
  IConformingDocumentsSummary,
} from '../../api-client/autogenerated';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import CircularLoader from '../loader/CircularLoader';
import pluralize from 'pluralize';
import { Autocomplete } from '@material-ui/lab';
import { updateObjectInStateArray } from '../../scripts/utils';

type ConfidenceMatchesTableProps = {
  type: PublishType;
  tableType: TableType;
  summary?: IConformingDocumentsSummary;
  rows: DCCRow[];
  setRows: React.Dispatch<React.SetStateAction<DCCRow[]>>;
  view: (row: DCCRow) => void;
  limitHeight: boolean;
  updateExisting?: (row: DCCRow) => void;
  saveChanges?: () => void;
  searchResult: string;
  isLoading: boolean;
  missingSections?: { code: string; description: string }[];
};

const useStyles = makeStyles(() =>
  createStyles({
    tableHeader: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default function ConfidenceMatchesTable(props: ConfidenceMatchesTableProps) {
  const classes = useStyles();
  const {
    type,
    tableType,
    summary,
    rows,
    setRows,
    view,
    limitHeight,
    updateExisting,
    saveChanges = () => {},
    searchResult,
    isLoading,
    missingSections,
  } = props;

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [rowForUpload, setRowForUpload] = useState<DCCRow>();

  const [packageName, setPackageName] = useState<string>();

  const updateParagraphNumber = (id: string, newParagraphNumber: string) => {
    updateObjectInStateArray(id, setRows, { paragraphNumber: newParagraphNumber });
  };

  const updateNumber = (id: string, newNumber: string) => {
    updateObjectInStateArray(id, setRows, { number: newNumber });
  };

  const updateTitle = (id: string, newTitle: string) => {
    updateObjectInStateArray(id, setRows, { title: newTitle });
  };

  const updatePackageName = () => {
    setRows((rows) => rows.map((r) => ({ ...r, packageName: packageName })));
    saveChanges();
  };

  const getRowBackgroundColor = (
    row: DCCRow,
    localConflict: ConflictType,
    backendConflict: ConformingCenterConflictResolutionType,
  ) => {
    if (row.isChecked) return greenHighlight;
    if (
      localConflict === ConflictType.Published ||
      (type === PublishType.Submittals &&
        backendConflict !== ConformingCenterConflictResolutionType.NoConflict)
    )
      return blueHighlight;
    if (!row.isGoodMatch) return yellowHighlight;
    return 'initial';
  };

  const handleCheckAll = () => {
    if (searchResult) {
      const filteredRows = getFilteredRows();
      const areAllFilteredRowsChecked = filteredRows.every((r) => r.isChecked);
      if (tableType === TableType.Deleted) {
        filteredRows.map((r) =>
          updateObjectInStateArray(r.id, setRows, { isChecked: !areAllFilteredRowsChecked }),
        );
      } else {
        const validFilteredRows = filteredRows.filter(rowIsValid);
        const areAllValidFilteredRowsChecked = validFilteredRows.every((r) => r.isChecked);
        validFilteredRows.map((r) =>
          updateObjectInStateArray(r.id, setRows, { isChecked: !areAllValidFilteredRowsChecked }),
        );
      }
    } else {
      (tableType === TableType.Deleted ? rows : validRows).map((r) =>
        updateObjectInStateArray(r.id, setRows, { isChecked: !areAllValidRowsChecked }),
      );
    }
  };

  const handleCheckRow = (id: string, checked: boolean) => {
    updateObjectInStateArray(id, setRows, { isChecked: checked });
  };

  const getFilteredRows = () => {
    const rowsToReturn = searchResult
      ? rows.filter(
          (row) =>
            (type !== PublishType.Specifications &&
              row.number.toLowerCase().includes(searchResult.toLowerCase())) ||
            row.title.toLowerCase().includes(searchResult.toLowerCase()),
        )
      : rows;
    if (type === PublishType.Specifications) {
      return rowsToReturn.sort(
        (a, b) =>
          (b.fileName || '').localeCompare(a.fileName || '') ||
          (a.pageRange?.[0] || 0) - (b.pageRange?.[0] || 0),
      );
    }
    if (type === PublishType.Submittals) {
      return rowsToReturn.sort(
        (a, b) =>
          (b.fileName || '').localeCompare(a.fileName || '') ||
          ascendingComparator(a, b, 'number') ||
          (a.paragraphNumber || '').localeCompare(b.paragraphNumber || '', 'en-US', {
            numeric: true,
          }),
      );
    }
    return rowsToReturn.sort(
      (a, b) =>
        (b.fileName || '').localeCompare(a.fileName || '') ||
        ascendingComparator(a, b, getSortPropertyFromPublishType(type)),
    );
  };

  const getTooltipTitle = (
    localConflict: ConflictType,
    backendConflict: ConformingCenterConflictResolutionType,
  ) => {
    if (localConflict === ConflictType.Unpublished)
      return `This item has the same ${getNumberGeneralLabel().toLowerCase()} as another item waiting to be published.`;
    if (localConflict === ConflictType.Published)
      return `AUTOMATIC CONFORMING\nThis ${getNumberGeneralLabel().toLowerCase()} matches a previously published ${getUserFriendlyDocumentNameFromPublishType(
        type,
      ).toLowerCase()}. This ${getUserFriendlyDocumentNameFromPublishType(
        type,
      ).toLowerCase()} will automatically become the current version and others will still be visible under "Previous Files" on the document detail page.`;
    if (backendConflict === ConformingCenterConflictResolutionType.Revision)
      return 'A submittal placeholder with the same section and paragraph numbers already exists. This submittal will automatically become the current version and others will still be visible under "Previous Files" on the document detail page.';
    if (backendConflict === ConformingCenterConflictResolutionType.Overwrite)
      return 'A submittal placeholder with the same section and paragraph numbers already exists and is either under review or complete. A new submittal placeholder will be created with the auto-ascending integer incremented by one.';
    return '';
  };

  const getTextFields = (
    row: DCCRow,
    localConflict: ConflictType,
    backendConflict: ConformingCenterConflictResolutionType,
  ) => {
    const { id, title, number, paragraphNumber, sectionDescription, packageName } = row;
    return (
      <>
        {shouldShowPackage && (
          <TableCell align="left">
            <TextField
              InputProps={{
                style: { height: 25, width: 100 },
              }}
              variant="outlined"
              fullWidth
              disabled
              value={packageName}
            />
          </TableCell>
        )}
        <TableCell align="left">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl>
              {type === PublishType.Specifications ? (
                <Autocomplete
                  freeSolo
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: { height: 25, width: 120, paddingLeft: 4 },
                        type: 'search',
                      }}
                      inputProps={{
                        style: { marginTop: -14 },
                        ...params.inputProps,
                      }}
                    />
                  )}
                  options={
                    missingSections
                      ?.filter((s) => !rows.find((r) => r.number === s.code))
                      .map((s) => s.code) || ([] as string[])
                  }
                  getOptionLabel={(code) => addSpacingIncremental(code)}
                  filterOptions={(options) => options.filter((o) => o.startsWith(number))}
                  inputValue={addSpacingIncremental(number)}
                  onInputChange={(event, value) => updateNumber(id, removeSpacing(value))}
                  onChange={(event, value) => {
                    const section = missingSections?.find((s) => s.code === value);
                    if (section) updateTitle(id, section.description);
                  }}
                  value={number}
                  style={{ height: 25, width: 120 }}
                />
              ) : (
                <TextField
                  InputProps={{
                    style: { height: 25, width: 100 },
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={shouldDisableNumberFields}
                  value={type === PublishType.Drawings ? number : addSpacingIncremental(number)}
                  onChange={(event) =>
                    updateNumber(
                      id,
                      type === PublishType.Drawings
                        ? event.target.value
                        : removeSpacing(event.target.value),
                    )
                  }
                  onBlur={saveChanges}
                />
              )}
            </FormControl>
            {(localConflict !== ConflictType.None ||
              backendConflict !== ConformingCenterConflictResolutionType.NoConflict) && (
              <Tooltip
                arrow
                PopperProps={{ style: { textAlign: 'center', whiteSpace: 'pre-wrap' } }}
                placement="top"
                title={getTooltipTitle(localConflict, backendConflict)}
              >
                <Info style={{ height: '0.8em', color: 'black', marginLeft: 10, marginTop: 2 }} />
              </Tooltip>
            )}
          </div>
        </TableCell>
        {type === PublishType.Submittals && (
          <>
            <TableCell>
              <FormControl fullWidth>
                <TextField
                  InputProps={{ style: { height: 25 } }}
                  variant="outlined"
                  fullWidth
                  value={paragraphNumber}
                  onChange={(event) => updateParagraphNumber(id, removeSpacing(event.target.value))}
                  onBlur={saveChanges}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl fullWidth>
                <TextField
                  InputProps={{ style: { height: 25 } }}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={sectionDescription}
                />
              </FormControl>
            </TableCell>
          </>
        )}
        <TableCell align="left">
          <FormControl fullWidth>
            <TextField
              InputProps={{ style: { height: 25 } }}
              variant="outlined"
              fullWidth
              value={title}
              onChange={(event) => updateTitle(id, event.target.value as string)}
              onBlur={saveChanges}
            />
          </FormControl>
        </TableCell>
      </>
    );
  };

  const handleAddFile = (file: File) => {
    if (!rowForUpload) return;
    const patch = {
      localFile: file,
      fileName: file.name,
    };
    setRowForUpload((prev) => ({ ...prev!, ...patch }));
    updateObjectInStateArray(rowForUpload.id, setRows, patch);
  };

  const handleRemoveFile = () => {
    if (!rowForUpload) return;
    const patch = {
      localFile: undefined,
      fileName: '',
    };
    setRowForUpload((prev) => ({ ...prev!, ...patch }));
    updateObjectInStateArray(rowForUpload.id, setRows, patch);
  };

  const getViewOrUploadButton = (row: DCCRow) => {
    if (tableType === TableType.Custom && !row.localFile) {
      return (
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setUploadDialogOpen(true);
              setRowForUpload(row);
            }}
            startIcon={<Add />}
            style={{ padding: '8px 10px' }}
          >
            Upload
          </Button>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            onClick={() => view(row)}
            startIcon={<Visibility fontSize="small" />}
            style={{ padding: '8px 15px' }}
          >
            View
          </Button>
        </TableCell>
      );
    }
  };

  const getNumberGeneralLabel = () => {
    switch (type) {
      case PublishType.Drawings:
      case PublishType.AsBuilts:
        return 'Sheet Number and Package';
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 'Section Number';
      default:
        return 'Number';
    }
  };

  const getNumberTableHeaderLabel = () => {
    switch (type) {
      case PublishType.Drawings:
      case PublishType.AsBuilts:
        return 'Sheet Number';
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 'Section Number';
      default:
        return 'Number';
    }
  };

  const getNumberTableHeaderWidth = () => {
    switch (type) {
      case PublishType.Drawings:
        return 100;
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 100;
    }
  };

  const getTitleTableHeaderLabel = () => {
    switch (type) {
      case PublishType.AsBuilts:
      case PublishType.Drawings:
        return 'Sheet Title';
      case PublishType.Specifications:
        return 'Section Description';
      case PublishType.Submittals:
        return 'Submittal Description';
      default:
        return 'Title';
    }
  };

  const getTitleTableHeaderWidth = () => {
    switch (type) {
      case PublishType.Drawings:
        return 600;
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 600;
    }
  };

  const getTruncatedFileName = (fileName?: string) => {
    if (!fileName) return '';
    if (fileName.length > 20) return `${fileName.substring(0, 20)}...`;
    return fileName;
  };

  const getConflictTypeFromRow = (row: DCCRow) => {
    if (!summary || type === PublishType.Submittals) return ConflictType.None;

    if (
      type === PublishType.Specifications &&
      summary.published.uniqSpecSections.includes(row.number)
    ) {
      return ConflictType.Published;
    } else if (
      summary.published.uniqDrawingsIdentifiers.some(
        (d) => d.sheetNumber === row.number && d.simplePackage === row.packageName,
      )
    ) {
      return ConflictType.Published;
    }

    if (
      rows.some((r) => !!r.number && !!row.number && r.number === row.number && r.id !== row.id)
    ) {
      return ConflictType.Unpublished;
    }

    return ConflictType.None;
  };

  const getAssociationsFromRow = (row: DCCRow) => {
    let value = '';
    if (row.associatedGroupIds.length > 0) {
      value += `${row.associatedGroupIds.length} ${pluralize(
        'Group',
        row.associatedGroupIds.length,
      )}`;
    }
    if (row.associatedUserIds.length > 0) {
      value += `${row.associatedGroupIds.length > 0 ? ', ' : ''}${
        row.associatedUserIds.length
      } ${pluralize('User', row.associatedUserIds.length)}`;
    }

    return value || 'None';
  };

  const rowIsValid = (row: DCCRow) => !!row.number && !!row.title;

  const validRows = rows.filter(rowIsValid);
  const areAllValidRowsChecked = validRows.length > 0 && validRows.every((row) => row.isChecked);
  const isAnyValidRowChecked = validRows.some((row) => row.isChecked);

  const areAllRowsChecked = rows.length > 0 && rows.every((row) => row.isChecked);
  const isAnyRowChecked = rows.some((row) => row.isChecked);

  const shouldDisableNumberFields = type === PublishType.Submittals;
  const shouldShowPageNumber = type === PublishType.Drawings;
  const shouldShowUpdateExisting = updateExisting !== undefined;
  const shouldShowAssociations =
    type === PublishType.Specifications || type === PublishType.Drawings;
  const shouldShowPageRange = type === PublishType.Specifications;
  const shouldShowPackage = type === PublishType.Drawings;

  useEffect(() => {
    if (type === PublishType.Drawings && rows.length > 0) {
      setPackageName(rows.find((r) => !!r.packageName)?.packageName || undefined);
    }
  }, [type, rows]);

  return (
    <TableContainer
      style={{
        maxHeight: limitHeight ? '450px' : 'none',
        minWidth: 'max-content',
        overflowY: limitHeight ? 'auto' : 'hidden',
      }}
    >
      <Table stickyHeader aria-label="dense">
        <TableHead>
          <TableCell padding="checkbox">
            <GreenCheck
              checked={tableType === TableType.Deleted ? areAllRowsChecked : areAllValidRowsChecked}
              indeterminate={
                tableType === TableType.Deleted
                  ? isAnyRowChecked && !areAllRowsChecked
                  : isAnyValidRowChecked && !areAllValidRowsChecked
              }
              onChange={handleCheckAll}
            />
          </TableCell>

          <TableCell className={classes.tableHeader} style={{ width: 0 }}>
            View {type === PublishType.Drawings ? 'Sheet' : 'Section'}
          </TableCell>

          {shouldShowPackage && (
            <TableCell align="left" width={80} className={classes.tableHeader}>
              <TextField
                InputProps={{
                  style: { height: 25, width: 100 },
                }}
                variant="outlined"
                fullWidth
                label="Package"
                InputLabelProps={{ shrink: true }}
                value={packageName}
                onChange={(event) => setPackageName(event.target.value)}
                onBlur={updatePackageName}
                onKeyDown={({ key }) => (key === 'Enter' ? updatePackageName() : null)}
              />
            </TableCell>
          )}

          <TableCell
            align="left"
            width={getNumberTableHeaderWidth()}
            className={classes.tableHeader}
          >
            {getNumberTableHeaderLabel()}
          </TableCell>
          {type === PublishType.Submittals && (
            <>
              <TableCell width={50}>Paragraph Number</TableCell>
              <TableCell width={400}>Section Description</TableCell>
            </>
          )}
          <TableCell
            align="left"
            width={getTitleTableHeaderWidth()}
            className={classes.tableHeader}
          >
            {getTitleTableHeaderLabel()}
          </TableCell>
          <TableCell width={1} className={classes.tableHeader}>
            {type === PublishType.Submittals ? 'Source File' : 'Filename'}
          </TableCell>
          {shouldShowPageNumber && (
            <TableCell width={1} className={classes.tableHeader}>
              Page # In This File
            </TableCell>
          )}
          {shouldShowPageRange && (
            <TableCell width={1} className={classes.tableHeader}>
              Page Range
            </TableCell>
          )}
          {shouldShowAssociations && (
            <TableCell width={1} className={classes.tableHeader}>
              Associations
            </TableCell>
          )}
          {shouldShowUpdateExisting && <TableCell />}
        </TableHead>
        <TableBody>
          {!isLoading ? (
            getFilteredRows().map((row) => {
              const conflict = getConflictTypeFromRow(row);
              return (
                <TableRow
                  hover
                  key={row.id}
                  role="checkbox"
                  style={{
                    backgroundColor: getRowBackgroundColor(row, conflict, row.conflictType),
                  }}
                >
                  <TableCell padding="checkbox">
                    <GreenCheck
                      onChange={(e, checked) => handleCheckRow(row.id, checked)}
                      checked={row.isChecked}
                      color="primary"
                    />
                  </TableCell>
                  {getViewOrUploadButton(row)}
                  {getTextFields(row, conflict, row.conflictType)}

                  <TableCell align="left">
                    <span title={row.fileName} style={{ whiteSpace: 'nowrap' }}>
                      {getTruncatedFileName(row.fileName)}
                    </span>
                  </TableCell>

                  {shouldShowPageNumber && <TableCell align="left">{row.pageNumber}</TableCell>}
                  {shouldShowPageRange && (
                    <TableCell align="left">{row.pageRange?.join('-')}</TableCell>
                  )}
                  {shouldShowAssociations && (
                    <TableCell align="left">{getAssociationsFromRow(row)}</TableCell>
                  )}
                  {shouldShowUpdateExisting && (
                    <TableCell align="left">
                      <TooltipIfDisabledComponent
                        arrow
                        placement="top"
                        title={
                          !row.number
                            ? `You may not manually version an item without a ${
                                type === PublishType.Drawings ? 'sheet' : 'section'
                              } number`
                            : `You may not manually version a ${
                                type === PublishType.Drawings ? 'sheet' : 'section'
                              } that will automatically version`
                        }
                        disabled={!row.number || conflict === ConflictType.Published}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!row.number || conflict === ConflictType.Published}
                          onClick={() => updateExisting!(row)}
                          style={{ padding: '8px 18px', whiteSpace: 'nowrap' }}
                        >
                          Manually Version {type === PublishType.Drawings ? 'Sheet' : 'Section'}
                        </Button>
                      </TooltipIfDisabledComponent>
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={100} style={{ height: 100 }}>
                <CircularLoader fullWidth />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {tableType === TableType.Custom && (
        <FileUploadDialog
          open={uploadDialogOpen}
          handleClose={() => setUploadDialogOpen(false)}
          title="Upload Section"
          addFile={(f) => handleAddFile(f as File)}
          removeFile={handleRemoveFile}
          file={rowForUpload?.localFile}
          disableComments
          disableDesignUpload
        />
      )}
    </TableContainer>
  );
}
