import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { NotificationSettingType } from '../../../api-client/autogenerated';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../../features/user/selectors';
import Button from '@material-ui/core/Button';
import { patchUser } from '../../../models/api/users';
import { fetchUser } from '../../../features/user/actions';
import CircularLoader from '../../loader/CircularLoader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

const getUserFacingName = (type: NotificationSettingType) => {
  switch (type) {
    case NotificationSettingType.None:
      return 'never.';
    case NotificationSettingType.AllWeekly:
      return 'every week.';
    case NotificationSettingType.AllDaily:
      return 'every day.';
    case NotificationSettingType.AllAsIssued:
      return 'in real time.';
  }
};

export default function SettingsNotificationForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(getUserState);

  const [isSaving, setIsSaving] = useState(false);

  const [emailPreference, setEmailPreference] = useState<NotificationSettingType | null>(null);
  const [reminderPreference, setReminderPreference] = useState<NotificationSettingType | null>(
    null,
  );

  const handleSave = async () => {
    try {
      setIsSaving(true);

      await patchUser(user.id, {
        notificationSetting: emailPreference,
        reminderNotificationSetting: reminderPreference,
      });

      await dispatch(fetchUser());
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setEmailPreference(user.notificationSetting || null);
    setReminderPreference(user.reminderNotificationSetting || null);
  }, [user]);

  return (
    <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: 500 }}>
      <Grid container spacing={2} justify="flex-start">
        <Grid item xs={11}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            <Typography variant="body1">I want to receive emails </Typography>
            <FormControl
              variant="outlined"
              fullWidth
              margin="none"
              style={{ marginLeft: 8, maxWidth: 130 }}
            >
              <Select
                value={emailPreference}
                displayEmpty
                margin="none"
                onChange={(event) =>
                  setEmailPreference(event.target.value as NotificationSettingType)
                }
              >
                {Object.values(NotificationSettingType).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {getUserFacingName(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={11}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            <Typography variant="body1">I want to receive reminder emails </Typography>
            <FormControl
              variant="outlined"
              fullWidth
              margin="none"
              style={{ marginLeft: 8, maxWidth: 130 }}
            >
              <Select
                value={reminderPreference}
                displayEmpty
                margin="none"
                onChange={(event) =>
                  setReminderPreference(event.target.value as NotificationSettingType)
                }
              >
                {Object.values(NotificationSettingType).map((item) => {
                  if (item === NotificationSettingType.AllAsIssued) return;
                  return (
                    <MenuItem key={item} value={item}>
                      {getUserFacingName(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid container>
          <Grid item>
            {isSaving ? (
              <CircularLoader size={40} style={{ marginLeft: 32, marginTop: 12 }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                onClick={handleSave}
                disabled={
                  emailPreference === user.notificationSetting &&
                  reminderPreference === user.reminderNotificationSetting
                }
                className={classes.margin}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
